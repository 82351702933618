import React, { useEffect, useState } from 'react';
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { useAlert } from "react-alert";
import comityLogo from '../../components/img/comityLogo.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useTranslation } from "react-i18next";

import userAction from "../../redux/users/action";


export const ForgetPass = () => {
    const { t } = useTranslation('login');
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const { setUser } = userAction;
    const dispatch = useDispatch();
    const alert = useAlert();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const isValidEmail = (email) => {
        let regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)*$/;
        return regexEmail.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isValidEmail(formData.email)) {
            return alert.show("L'email n'est pas correctement formaté");
        }
        console.log(isValidEmail);
        const auth = getAuth();
        await sendPasswordResetEmail(auth, formData.email)
            .then(() => {
                // Email de réinitialisation du mot de passe envoyé !
                alert.show(t('verification_sent'));
                setFormData({});
                navigate("/login");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                alert.show("Une erreur s'est produite.");
            });

    }

    
    return (
        <div className='loginPage'>
            <div className="container">
                <div className="row ai-center">
                    <div className="col-md-8">
                        <div className="commityImg">
                            <img className='img-fluid' src={comityLogo} alt="" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="loginForm">
                            <h3>{t('reset_password')}</h3>
                            {/* <div className="desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div> */}
                            <form onSubmit={handleSubmit}>
                                <div className="form-goup">
                                    <label>Email</label>
                                    <input name='email' value={formData.email} onChange={(e)=>handleChange(e)} required className='form-control' type="text" />
                                </div>
                                {/* <div className="form-goup">
                                    <label>Password</label>
                                    <input name='password' value={formData.password} onChange={(e)=>handleChange(e)} required className='form-control' type="password" />
                                </div> */}
                                {/* <div className="forget">Forgot Password?</div> */}
                                <button className="loginBtn" type='submit'>{t('submit')}</button>
                                {/* <div className="noAccount">Don’t have a account?</div>
                                <div className="signup">Signup</div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
