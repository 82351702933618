import ENV from "../env";

const getOrgProducts = async (orgId) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/organization/${orgId}/products`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Get organization's products failed: " + resData.message);
  }

  return resData;
};

export default getOrgProducts;
