import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartArray: [],
  email: "",
};
export const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      let item = action.payload;
      if (state.cartArray.find((i) => i.id === item.id)) {
        let temp = state.cartArray;
        temp.map((i) => {
          if (i.id === item.id) {
            i.totalDue = i.totalDue + parseFloat(action.payload.price);
            i.quantity = i.quantity + 1;
          }
        });
        state.cartArray = temp;
      } else {
        let temp = [...state.cartArray];
        temp.push({
          ...action.payload,
          totalDue: parseFloat(action.payload.price),
          quantity: 1,
        });
        state.cartArray = temp;
      }
    },
    addTenItemToCart: (state, action) => {
      let item = action.payload;
      if (state.cartArray.find((i) => i.id === item.id)) {
        let temp = state.cartArray;
        temp.map((i) => {
          if (i.id === item.id) {
            i.totalDue = i.totalDue + parseFloat(action.payload.price * 10);
            i.quantity = i.quantity + 10;
          }
        });
        state.cartArray = temp;
      } else {
        let temp = [...state.cartArray];
        temp.push({
          ...action.payload,
          totalDue: parseFloat(action.payload.price * 10),
          quantity: 10,
        });
        state.cartArray = temp;
      }
    },
    removeItemFromCart: (state, action) => {
      let item = action.payload;
      if (state.cartArray.find((i) => i.quantity == 1)) {
        let temp = state.cartArray;
        let newTemp = temp.filter((i) => i.id != item.id);
        state.cartArray = newTemp;
      } else {
        let temp = state.cartArray;
        temp.map((i) => {
          if (i.id === item.id) {
            i.totalDue = i.totalDue - parseFloat(action.payload.price);
            i.quantity = i.quantity - 1;
          }
        });
        state.cartArray = temp;
      }
    },
    emptyWholeCart: (state, action) => {
      state.cartArray = [];
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    clearEmail: (state) => {
      state.email = "";
    },
  },
});

export const {
  addItemToCart,
  addTenItemToCart,
  removeItemFromCart,
  emptyWholeCart,
  setEmail,
  clearEmail,
} = cartSlice.actions;
export const getCartArray = (state) => state.Cart.cartArray;
export const getEmail = (state) => state.Cart.email;

export default cartSlice.reducer;
