import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CareModal from "./CareModal";

const CashlessServed = () => {
	const [careModalOpen, setCareModalOpen] = React.useState(false);
	const [promo, setPromo] = React.useState(true);
	const navigate = useNavigate();
	const params = useParams();
	return (
		<>
			<div className="d-flex flex-column" style={{ minHeight: "100dvh" }}>
				<div className="page-header">
					<div className="container">
						<h2 className="title" style={{ color: "#27AE60" }}>
							You are served :)
						</h2>
						<div>Go to the bar and show them this screen.</div>
					</div>
				</div>
				<div className="container px-20px py-4">
					<div className="summary-title-2">Order Summary</div>
					<div className="summary">
						<ul>
							<li>
								<span>Heoneken Beer</span>
								<span className="count">x3</span>
							</li>
							<li>
								<span>Col</span>
								<span className="count">x2</span>
							</li>
							<li>
								<span>Bail Goblet</span>
								<span className="count">x5</span>
							</li>
						</ul>
					</div>
				</div>
				<div className="mt-auto">
					{promo && (
						<div className="text-center">
							<img src="/img/promo.png" alt="promo" className="mw-100" />
						</div>
					)}
					<div className="page-header pt-3 pb-4">
						<div className="container">
							<div className="total--price mt-0 mb-12px">
								<span>Total</span>
								<span style={{ color: "#27AE60" }}>€ 13.99</span>
							</div>
							<button
								type="button"
								className="submitBtn submitBtn2 m-0"
								onClick={() => {
									navigate(`/order/${params.orderId}/success`);
								}}
								style={{
									background:
										"linear-gradient(91.24deg, #00C29F 0.26%, #009FC2 99.58%)",
								}}
							>
								Back
							</button>
						</div>
					</div>
				</div>
			</div>
			<CareModal open={careModalOpen} setOpen={setCareModalOpen} />
		</>
	);
};

export default CashlessServed;
