import ENV from "../env";

const createStripeAccount = async (customerDetails, stripeId, orgId) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/stripe/create-account`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        details: customerDetails,
        stripeId: stripeId,
        orgId: orgId,
      }),
    }
  );

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create stripe account failed: " + resData.message);
  }

  const charges_enabled = resData.charges_enabled;

  return charges_enabled;
};

export default createStripeAccount;