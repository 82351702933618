import ENV from "../env";

const updateParticipant = async (participant) => {
  const response = await fetch(`${ENV.firebaseApiUrl}/participant/paid`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      participant
    }),
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("La confirmation de paiement n'a pas fonctionné :: " + resData.message);
  }

  return true;
};

export default updateParticipant;
