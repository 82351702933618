import React from "react";

export const PlusIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1635_1193)">
        <path
          d="M11.001 11.5V5.5H13.001V11.5H19.001V13.5H13.001V19.5H11.001V13.5H5.00098V11.5H11.001Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1635_1193">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const MinusIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1635_1197)">
        <path
          d="M11.001 11.5H13.001H19.001V13.5H13.001H11.001H5.00098V11.5H11.001Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1635_1197">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const BackIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.785 8.4725H20V10.9725H4.785L11.49 17.6775L9.7225 19.445L0 9.7225L9.7225 0L11.49 1.7675L4.785 8.4725Z"
        fill="white"
      />
    </svg>
  );
};
export const ClearIcon = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="40"
        height="40"
        rx="8"
        fill="white"
        fill-opacity="0.05"
      />
      <g clip-path="url(#clip0_1635_1985)">
        <path
          d="M19.9997 19.0867L24.9497 14.1367L26.3637 15.5507L21.4137 20.5007L26.3637 25.4507L24.9497 26.8647L19.9997 21.9147L15.0497 26.8647L13.6357 25.4507L18.5857 20.5007L13.6357 15.5507L15.0497 14.1367L19.9997 19.0867Z"
          fill="#8BA4BF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1635_1985">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AllIcon = () => <img src="/img/all.svg" alt="" />;
export const BasketIcon = () => <img src="/img/shopping.svg" alt="" />;
export const CardIcon = () => <img src="/img/card.svg" alt="" />;
export const TrashIcon = () => <img src="/img/bin.svg" alt="" />;
