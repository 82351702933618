import React from "react";
import bg1 from "./img/banner-bg.png";
import bg2 from "./img/banner-bg2.png";
import LoadingSpinner from "../components/LoadingSpinner";

const bannerBg = "https://firebasestorage.googleapis.com/v0/b/yummi-events.appspot.com/o/events%2F001%2FFrame%201000002401.png?alt=media&token=13f54825-ec55-41df-8d60-44011c85c319";

const HomeBanner = props => {
	const [mobileScreen, setMobileScreen] = React.useState(true);

	const handleMobile = () => {
		const width = window.innerWidth;
		if (width < 767) {
			setMobileScreen(true);
		} else {
			setMobileScreen(false);
		}
	};

	React.useEffect(() => {
		handleMobile();
		window.addEventListener("resize", handleMobile);
	}, []);
	return (
		<section
			className="banner-section"
			style={{
				background: `url(${bannerBg}) no-repeat center center / cover`,
			}}
		>
			<div className="container">
				<div className="banner-content">
					<h1 className="title">{props.title}</h1>
				</div>
			</div>
		</section>
	);
};

export default HomeBanner;
