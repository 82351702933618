import {
  Icons,
  Beers,
  Softs,
  Food,
  Cocktails,
  Wine,
  HotDrinks,
  Others,
} from '../images/icons';

export const activity = [
  {
    id: '1',
    label: 'Club de sport',
    value: 'Club de sport',
  },
  {
    id: '2',
    label: 'Comité de Jeunesse',
    value: 'Comité de Jeunesse',
  },
  {
    id: '3',
    label: 'Comité scolaire',
    value: 'Comité scolaire',
  },
  {
    id: '4',
    label: 'Comité des fêtes',
    value: 'Comité des fêtes',
  },
  {
    id: '5',
    label: 'Folklore',
    value: 'Folklore',
  },
  {
    id: '6',
    label: 'ASBL',
    value: 'ASBL',
  },
  {
    id: '7',
    label: "Entreprise d'événementiel",
    value: 'Entreprise',
  },
  {
    id: '8',
    label: 'Autre',
    value: 'Autre',
  },
];


export const roles = [
  {
    id: '1',
    label: 'Administrateur',
    value: 'Admin',
  },
  {
    id: '2',
    label: 'Caissier',
    value: 'Cashier',
  },
  {
    id: '3',
    label: 'Steward',
    value: 'Steward',
  },
];
export const BeersArray = [
  {
    name: 'Beer',
    icon: Icons.beer,
  },
  {
    name: 'blond',
    icon: Beers.blond,
  },
  {
    name: 'bottle',
    icon: Beers.bottle,
  },
  {
    name: 'bottle1',
    icon: Beers.bottle1,
  },
  {
    name: 'bottle2',
    icon: Beers.bottle2,
  },
  {
    name: 'brown',
    icon: Beers.brown,
  },
  {
    name: 'can',
    icon: Beers.can,
  },
  {
    name: 'jug',
    icon: Beers.jug,
  },
  {
    name: 'mug',
    icon: Beers.mug,
  },
  {
    name: 'pils',
    icon: Beers.pils,
  },
  {
    name: 'pint',
    icon: Beers.pint,
  },
  {
    name: 'special',
    icon: Beers.special,
  },
  {
    name: 'tap',
    icon: Beers.tap,
  },
  {
    name: 'glass',
    icon: Beers.glass,
  },
  {
    name: 'box',
    icon: Beers.box,
  },
  {
    name: 'cherry',
    icon: Beers.cherry,
  },
  {
    name: 'pong',
    icon: Beers.pong,
  },
  {
    name: 'tray',
    icon: Beers.tray,
  },
];
export const SoftsArray = [
  {
    name: 'Softs',
    icon: Icons.soft,
  },
  {
    name: 'chocolateMilk',
    icon: Softs.chocolateMilk,
  },
  {
    name: 'cola',
    icon: Softs.cola,
  },
  {
    name: 'colaCan',
    icon: Softs.colaCan,
  },
  {
    name: 'energyDrink',
    icon: Softs.energyDrink,
  },
  {
    name: 'icedTea',
    icon: Softs.icedTea,
  },
  {
    name: 'juice',
    icon: Softs.juice,
  },
  {
    name: 'lemon',
    icon: Softs.lemon,
  },
  {
    name: 'lemonCan',
    icon: Softs.lemonCan,
  },
  {
    name: 'orange',
    icon: Softs.orange,
  },
  {
    name: 'orangeCan',
    icon: Softs.orangeCan,
  },
  {
    name: 'water',
    icon: Softs.water,
  },
  {
    name: 'sparkling',
    icon: Softs.sparkling,
  },
  {
    name: 'drink',
    icon: Softs.drink,
  },
];
export const FoodArray = [
  {
    name: 'Food',
    icon: Icons.food,
  },
  {
    name: 'burger',
    icon: Food.burger,
  },
  {
    name: 'cheese',
    icon: Food.cheese,
  },
  {
    name: 'chips',
    icon: Food.chips,
  },
  {
    name: 'cottonCandy',
    icon: Food.cottonCandy,
  },
  {
    name: 'fries',
    icon: Food.fries,
  },
  {
    name: 'hotdog',
    icon: Food.hotdog,
  },
  {
    name: 'iceCream',
    icon: Food.iceCream,
  },
  {
    name: 'noodles',
    icon: Food.noodles,
  },
  {
    name: 'pizza',
    icon: Food.pizza,
  },
  {
    name: 'popCorn',
    icon: Food.popCorn,
  },
  {
    name: 'potatoChips',
    icon: Food.potatoChips,
  },
  {
    name: 'sausage',
    icon: Food.sausage,
  },
  {
    name: 'sweets',
    icon: Food.sweets,
  },
  {
    name: 'meatballs',
    icon: Food.meatballs,
  },
  {
    name: 'barbecueSausage',
    icon: Food.barbecueSausage,
  },
  {
    name: 'bread',
    icon: Food.bread,
  },
  {
    name: 'friedChicken',
    icon: Food.friedChicken,
  },
  {
    name: 'sandwich',
    icon: Food.sandwich,
  },
  {
    name: 'sauce',
    icon: Food.sauce,
  },
  {
    name: 'pasta',
    icon: Food.pasta,
  },
  {
    name: 'steak',
    icon: Food.steak,
  },
  {
    name: 'chicken',
    icon: Food.chicken,
  },
];
export const CocktailsArray = [
  {
    name: 'Cocktails',
    icon: Icons.cocktail,
  },
  {
    name: 'blueCocktail',
    icon: Cocktails.blueCocktail,
  },
  {
    name: 'cubaLibre',
    icon: Cocktails.cubaLibre,
  },
  {
    name: 'gin',
    icon: Cocktails.gin,
  },
  {
    name: 'margarita',
    icon: Cocktails.margarita,
  },
  {
    name: 'mojito',
    icon: Cocktails.mojito,
  },
  {
    name: 'pinkCocktail',
    icon: Cocktails.pinkCocktail,
  },
  {
    name: 'redCocktail',
    icon: Cocktails.redCocktail,
  },
  {
    name: 'yellowCocktail',
    icon: Cocktails.yellowCocktail,
  },
  {
    name: 'pastis',
    icon: Cocktails.pastis,
  },
];
export const WineArray = [
  {
    name: 'Wine',
    icon: Icons.wine,
  },
  {
    name: 'champagne',
    icon: Wine.champagne,
  },
  {
    name: 'pinkBottle',
    icon: Wine.pinkBottle,
  },
  {
    name: 'pinkGlass',
    icon: Wine.pinkGlass,
  },
  {
    name: 'redBottle',
    icon: Wine.redBottle,
  },
  {
    name: 'redGlass',
    icon: Wine.redGlass,
  },
  {
    name: 'whiteBottle',
    icon: Wine.whiteBottle,
  },
  {
    name: 'whiteGlass',
    icon: Wine.whiteGlass,
  },
];
export const HotDrinksArray = [
  {
    name: 'Hot drinks',
    icon: Icons.drink,
  },
  {
    name: 'coffee',
    icon: HotDrinks.coffee,
  },
  {
    name: 'hotChoco',
    icon: HotDrinks.hotChoco,
  },
  {
    name: 'hotWine',
    icon: HotDrinks.hotWine,
  },
  {
    name: 'soup',
    icon: HotDrinks.soup,
  },
  {
    name: 'tea',
    icon: HotDrinks.tea,
  },
];
export const OtherArray = [
  {
    name: 'Other',
    icon: Icons.others,
  },
  {
    name: 'bands',
    icon: Others.bands,
  },
  {
    name: 'beerPong',
    icon: Others.beerPong,
  },
  {
    name: 'bracelet',
    icon: Others.bracelet,
  },
  {
    name: 'hammering',
    icon: Others.hammering,
  },
  {
    name: 'cards',
    icon: Others.cards,
  },
  {
    name: 'ticket',
    icon: Others.ticket,
  },
  {
    name: 'hat',
    icon: Others.hat,
  },
  {
    name: 'shirt',
    icon: Others.shirt,
  },
];
export const Category = [
  {
    id: '1',
    label: 'Beers',
    value: 'Beers',
    color: '#e016b4',
    logo: Icons.beer,
    logoArray: BeersArray,
    Icons: [
      'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/tickets%2Fbeer.png?alt=media&token=0469574f-fffd-427e-85dd-a1a278ce8df5',
    ],
  },
  {
    id: '2',
    label: 'Softs',
    value: 'Softs',
    color: '#7220e5',
    logo: Icons.soft,
    logoArray: SoftsArray,
    Icons: [
      'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/tickets%2Fsoft.png?alt=media&token=6ca9f920-b352-42a2-ace4-24894e479160',
    ],
  },
  {
    id: '3',
    label: 'Food',
    value: 'Food',
    color: '#ff0000',
    logo: Icons.food,
    logoArray: FoodArray,
    Icons: [
      'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/tickets%2Ffood.png?alt=media&token=e91b67a9-9851-45e3-980b-61ab98c353dd',
    ],
  },
  {
    id: '4',
    label: 'Cocktails',
    value: 'Cocktails',
    color: '#00d8ff',
    logo: Icons.cocktail,
    logoArray: CocktailsArray,
    Icons: [
      'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/tickets%2Fcocktail.png?alt=media&token=30bebd00-fe09-46ae-859c-5612ee4853f3',
    ],
  },
  {
    id: '5',
    label: 'Wine',
    value: 'Wine',
    color: '#fff700',
    logo: Icons.wine,
    logoArray: WineArray,
    Icons: [
      'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/tickets%2Fwine.png?alt=media&token=44ca8f41-82a1-496b-ad95-1b2f4d0aa7fe',
    ],
  },
  {
    id: '6',
    label: 'Hot',
    value: 'Hot drinks',
    color: '#00ffae',
    logo: Icons.drink,
    logoArray: HotDrinksArray,
    Icons: [
      'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/tickets%2Fdrink.png?alt=media&token=e094e77d-f9c9-4ffe-985e-88beb957df17',
    ],
  },

  {
    id: '7',
    label: 'Other',
    value: 'Other',
    color: '#ff9d00',
    logo: Icons.others,
    logoArray: OtherArray,
    Icons: [
      'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/tickets%2Fothers.png?alt=media&token=d89e641d-e6ff-4033-a528-7caa4eb11038',
    ],
  },
];
export const WebshopCategories = [
  {
    id: '1',
    label: 'Boissons',
    value: 'Boissons',
    color: '#e016b4',
    logo: Icons.beer,
  },
  {
    id: '2',
    label: 'Gobelets',
    value: 'Gobelets',
    color: '#7220e5',
    logo: Icons.cup,
  },
  {
    id: '3',
    label: 'Services',
    value: 'Services',
    color: '#ff0000',
    logo: Icons.insurance,
  },
  {
    id: '4',
    label: 'Matériels',
    value: 'Matériels',
    color: '#00d8ff',
    logo: Icons.pos,
  },
  {
    id: '4',
    label: 'Toilettes',
    value: 'Toilettes',
    color: '#00d8ff',
    logo: Icons.wc,
  },
];

export const priority = [
  {
    id: '1',
    label: 'High',
    value: 'High',
  },
  {
    id: '2',
    label: 'Low',
    value: 'Low',
  },
];

export const WebShopProducts = [
  {
    id: 1,
    title: 'Rekwup',
    subTitle: 'Goblets reutilisables',
    shortDescription: 'Louer des gobletes reutilisables pour votre evenement.',
    description: 'Pour chaque évènement professionnel, particulier ou associatif, optez pour une solution zéro déchet en utilisant les gobelets réutilisables Rekwup. Nous les fournissons, les lavons et les stockons pour vous, tout en vous garantissant les démarches les plus écoresponsables possibles.',
    logo: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Frekwup%2Frekwup_logo.jpeg?alt=media&token=e9d7bba0-d0b7-46d4-9eaf-715dd31fbeaa',
    image: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Frekwup%2Frekwup_wall.jpeg?alt=media&token=fc63bee6-cd91-49dc-8e38-2004ed43bb96',
    category: 'Gobelets',
    email: 'info@rekwup.be',
    message: 'Veuillez préciser le nombre de participants, les autres produits souhaités (cruche, paille), le type de verres nécessaires (gobelet, verre à vin, flûte) et si vous souhaitez être livré ou venir cherché la commande.'
  },
  {
    id: 2,
    title: "B'JOY Beer",
    subTitle: 'Bière locale',
    shortDescription: 'Découvrez la bière qui rapproche les gens.',
    description: `Ce qui distingue vraiment B'JOY, c'est son esprit de rassemblement. Nous croyons que la bière peut être un moyen de rassembler et de créer des liens durables. C'est pourquoi,  nous avons créé B'Joy Beer en 2020. Un projet qui permettrait de partager des valeurs qui nous sont chères : la convivialité, le partage et la joie.​`,
    logo: "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fbjoy%2Fbjoy_logo.png?alt=media&token=b9d48ed8-3460-43e1-9c13-3fcbf315b232",
    image: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fbjoy%2Fbjoy_wall.png?alt=media&token=ed923936-ec80-4624-a133-bf49628d92aa',
    category: 'Boissons',
    redirectionUrl: 'https://www.bjoybeer.be/shop',
    email: 'bjoy-beer@hotmail.com',
    image2: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fbjoy%2Fbjoy_image.webp?alt=media&token=88453258-898a-4975-8a57-15c231438eb1',
  },
  {
    id: 3,
    title: "Gembloux Beer",
    subTitle: 'Bière locale',
    shortDescription: 'Goutez aux bières de la brasserie universitaire de Gembloux.',
    description: `🍺🍯 L’ABBAYE DE GEMBLOUX, c’est par elle que tout a commencé !
    Un recette héritée de la tradition estudiantine de Gembloux, un goût malté complexe aux arômes floraux et faite avec du bon miel de Floreffe. Avouez … vous aussi vous l’adorez ?!
    Gembloux.BEER, des bières de goût made in Gembloux.`,
    logo: "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fgemblouxbeer%2Fgemblouxbeer_logo.png?alt=media&token=b3f28141-3298-47eb-a607-96aa0361c27c",
    image: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fgemblouxbeer%2Fgemblouxbeer_wall.jpeg?alt=media&token=b969634b-da48-48a8-8999-8899b62a7986',
    image2: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fgemblouxbeer%2Fgemblouxbeer_image.png?alt=media&token=5eb90609-ea98-4238-8b7c-fdf0ca48f456',
    category: 'Boissons',
    redirectionUrl: 'https://www.gembloux.beer/shop',
    email: 'info@gembloux.beer',
  },
  {
    id: 4,
    title: "Les WC cabines namurois",
    subTitle: 'Location de WC',
    shortDescription: 'Locations de sanitaires mobiles pour tous vos événements.',
    description: `Les WC Cabines Namurois propose des produits adaptés à la demande sans cesse croissante dans la domaines des toilettes autonomes.
    Nous nous démarquons au travers de la mise à disposition de toilettes pratiques, urinoirs & lave-mains sur les chantiers et les sites de manifestations en extérieur.`,
    logo: "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fwcnamurois%2Fwcnamurois_logo.png?alt=media&token=eb7102c3-c720-4566-9d6e-b0954cc2a6a1",
    image: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fwcnamurois%2Fwcnamurois_wall.webp?alt=media&token=74ffe032-93f0-44ac-8e43-5c27c6d4ed8c',
    category: 'Toilettes',
    email: 'info@cabinesnamurois.be',
    message: 'Veillez préciser le nombre de Cabine Standard, Cabine VIP Confort, Cabine PMR, Urinoirs et Lave-mains.',
  },
  {
    id: 5,
    title: "MyUnisono",
    subTitle: 'Sabam',
    shortDescription: "Demander une autorisation pour diffuser de la musique.",
    description: `Vous organisez un événement ? Il est alors très probable que vous utilisiez de la musique. Mais pour cela, vous avez besoin d’une autorisation d’Unisono. Demandez-la au moins cinq jours avant votre événement.`,
    logo: "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Funisono%2Funisono_wall.jpeg?alt=media&token=420be670-802d-4f04-9f7b-6afefb6198bf",
    image: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Funisono%2Funisono_wall.jpeg?alt=media&token=420be670-802d-4f04-9f7b-6afefb6198bf',
    category: 'Services',
    redirectionUrl: 'https://eservices.sabam.be/pls/apex/f?p=60010:41:0::NO::P0_HDN_SESSION_LANG:fr-be',
    email: null,
    image2: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Funisono%2Fpersonnes-au-concert-texture-superposition-fumee%20(2).jpg?alt=media&token=fe161f63-2385-4bcb-9695-073ade1b5499',
  },
  {
    id: 6,
    title: "SumUp",
    subTitle: 'Paiement par carte',
    shortDescription: "Acheter un SumUp pour accepter les paiements par carte sur Comitty.",
    description: `Commandez un lecteur de carte SumUp à prix réduit en utilisant le code parrain de Comitty. Acceptez les paiements par carte lors de vos événements !`,
    logo: "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fsumup%2Fsumupair_wall.jpeg?alt=media&token=13c223a6-1708-4548-ac07-91f9145eff40",
    image: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fsumup%2Fsumupair_wall.jpeg?alt=media&token=13c223a6-1708-4548-ac07-91f9145eff40',
    category: 'Matériels',
    redirectionUrl: 'https://www.sumup.com/fr-be/refer-a-friend/?prc=CRPRT2ABENL-s-CRPRT2ABBENL-s-CRPRT23GBBENL-s-CRPRT2SOBENL&products=card_reader.air%2Ccard_reader.air_bundle%2Ccard_reader.bp55_bundle_printer%2Ccard_reader.solo_bundle_cradle&utm_content=personal_url&utm_medium=referafriend&referrer=MCLC3ZPF&fbuy_ref_code=tuqP5&utm_source=Friendbuy&utm_campaign=RF_BE_nl_D_reward-amount%3D10_referral-offer%3D30',
    email: null,
    image2: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fsumup%2Fsumup_image.png?alt=media&token=89037fc8-7c1c-4782-b3be-f2c2a3ee91eb',
  },
  {
    id: 7,
    title: "Payconiq",
    subTitle: 'Paiement QR code',
    shortDescription: "Inscrivez-vous sur Payconiq pour accepter les paiements par QR.",
    description: `Seulement 0.06€ par transaction! Remplissez le formulaire d'inscription à Payconiq pour recevoir votre clé marchande que vous pourrez enregistrer dans Comitty afin d'accepter les paiements par QR code.* Le processus d'inscription peut prendre jusqu'à 1 mois ou plus.\n*Offre réservée aux abonnés de Comitty.`,
    logo: "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fpayconiq%2Fpayconiq_logo.png?alt=media&token=a7f56f48-63ea-4ec6-81a6-fd4f3ec296b2",
    image: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fpayconiq%2Fpayconiq_wall.jpeg?alt=media&token=0166db61-fbc0-47d2-9a81-04cfc9048d66',
    category: 'Services',
    redirectionUrl: 'https://www.payconiq.be/merchant-contract/zlJx4OYLFyBKk99pJaE4adOyy9ICkQ?lang=fr',
    email: null,
    image2: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fpayconiq%2Fpayconiq_image.webp?alt=media&token=5c01e8f3-8770-4658-a663-d76f9e773a5b',
  },
  {
    id: 7,
    title: "Starlink",
    subTitle: 'Connexion Internet',
    shortDescription: "Pas de connexion 4G à votre événement? Pas de problème.",
    description: `Louer l'une de nos antennes Starlink pour équipper votre événement d'une connexion haut débit où que vous soyez*! Et ainsi pouvoir utiliser Comitty et accepter les paiements électroniques.**\n*Offre réservée aux abonnés de Comitty.\n**Nécessite un emplacement avec vue dégagé sur le ciel à moins de 30m de la caisse.`,
    logo: "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fstarlink%2Fstarlink_wall.jpeg?alt=media&token=91c6fbe7-587d-47a2-b729-821e8afb2631",
    image: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fstarlink%2Fstarlink_logo.webp?alt=media&token=7ae82817-22f6-4173-9493-6bdc0e757bc1',
    category: 'Matériels',
    email: 'info@yummy-technologies.be',
    message: 'Nécessite un emplacement avec vue dégagé sur le ciel à moins de 30m de la caisse et une source de courant.',
    video: 'https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fstarlink%2Fssstik.io_1684244861416.mp4?alt=media&token=ef09d333-881e-42e6-8e30-11af7198d09e',
    image2: "https://firebasestorage.googleapis.com/v0/b/comitty-79c84.appspot.com/o/shops%2Fstarlink%2FIMG_0898.jpg?alt=media&token=a4c060b1-884b-4ab1-8bd9-84e503311e7f",
  },
  // {
  //   id: 4,
  //   title: 'Assureur',
  //   subTitle: 'Assurance',
  //   description: 'Assurez votre soiree chez notre partenaire assureur.',
  //   logo: Icons.gobletes,
  //   category: 'Services',
  // },
];

export const ticketData = [
  {
    id: 1,
    name: 'Abc',
    available: '400',
    revenue: '10',
    price: '20',
  },
  {
    id: 2,
    name: 'CDE',
    available: '200',
    revenue: '10',
    price: '400',
  },
];

export const participantsList = [
  {
    id: 1,
    name: 'Albert One',
    email: 'one@gmail.com',
    address: 'Gardern steet, Barcelona, NA',
    age: '20',
    color: 'green',
  },
  {
    id: 2,
    name: 'Bravo Smith',
    email: 'bravo@gmail.com',
    address: 'Gardern steet, Barcelona, NA',
    age: '20',
    color: 'orange',
  },
  {
    id: 3,
    name: 'Charli Lee',
    email: 'lee@gmail.com',
    address: 'Gardern steet, Barcelona, NA',
    age: '20',
    color: 'yellow',
  },
  {
    id: 4,
    name: 'Delta VanBerg',
    email: 'delta@gmail.com',
    address: 'Gardern steet, Barcelona, NA',
    age: '20',
    color: 'pink',
  },
  {
    id: 5,
    name: 'John Doe',
    email: 'doe@gmail.com',
    address: 'Gardern steet, Barcelona, NA',
    age: '20',
    color: 'purple',
  },
];
