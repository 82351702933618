import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  addItemToCart,
  addTenItemToCart,
  getCartArray,
  removeItemFromCart,
  emptyWholeCart,
} from "../../redux/cart/reducer";
import { MinusIcon, PlusIcon } from "../../components/Icon";
import {
  BeersArray,
  CocktailsArray,
  FoodArray,
  HotDrinksArray,
  OtherArray,
  SoftsArray,
  WineArray,
  Category,
} from "../../utility/data";
import { Icons } from "../../images/icons";

const CashlessModal = ({ open, setOpen, total, bails, setCareModalOpen }) => {
  const dispatch = useDispatch();
  const cartStore = useSelector((state) => state.cartReducer.cartArray);

  const [newdata, setNewdata] = React.useState(data);
  //const [total, setTotal] = React.useState(0);
  const navigate = useNavigate();
  //   useEffect(() => {
  //     setTotal(
  //       newdata.reduce((acc, item) => {
  //         return acc + item.price * item.quantity;
  //       }, 0)
  //     );
  //   }, [newdata]);
  const params = useParams();

  return (
    <>
      <Modal onHide={setOpen} show={open} centered className="cashless-modal">
        <Modal.Body>
          <h2 className="title">Vous n'avez rien oublié?</h2>
          {/*  */}
          {bails.map((item, index) => (
            <CashlessItem
              cart={cartStore}
              add={(item) => dispatch(addItemToCart(item))}
              remove={(item) => dispatch(removeItemFromCart(item))}
              key={item.id}
              item={item}
            />
          ))}
          <div className="total--price">
            <span>Total</span>
            <span style={{ color: "#27AE60" }}>€{total.toFixed(2)}</span>
          </div>
          <button
            type="button"
            className="submitBtn submitBtn2"
            onClick={() => {
              if (total < 10) {
                setOpen(false);
                setCareModalOpen(true);
              } else {
                navigate(`/cashless/${params.orgId}/payment`);
              }
            }}
          >
            confirmer
          </button>
          <button
            type="reset"
            onClick={() => setOpen(false)}
            className="backBtn"
          >
            Retour
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};
const CashlessItem = ({ item, setNewdata, cart, add, remove }) => {
  const prdIndex = cart.find((prd) => prd.id === item.id);
  return (
    <>
      <div className="cashless-item">
        <div className="img">
          <img
            src={
              item?.customIcon
                ? item.category === "Food"
                  ? FoodArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Softs"
                  ? SoftsArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Beers"
                  ? BeersArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Cocktails"
                  ? CocktailsArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Wine"
                  ? WineArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Hot drinks"
                  ? HotDrinksArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Other" &&
                    OtherArray.find((i) => i.name === item?.customIcon).icon
                : item.category == "Food"
                ? Icons.food
                : item.category == "Softs"
                ? Icons.soft
                : item.category == "Beers"
                ? Icons.beer
                : item.category == "Cocktails"
                ? Icons.cocktail
                : item.category == "Hot drinks"
                ? Icons.drink
                : item.category == "Other" && Icons.others
            }
            alt="item"
          />
        </div>
        <div className="content">
          <p className="subtext">{item.subtitle}</p>
          <h3 className="subtitle">{item.title}</h3>
          <div className="price">
            <span>x{prdIndex ? prdIndex.quantity : "0"}</span>
            <span style={{ color: "#27AE60" }}>
              {(item.price * 1).toFixed(2)}€
            </span>
          </div>
        </div>
        <div className="button-group">
          <button
            type="button"
            onClick={() => {
              add(item);
            }}
          >
            <PlusIcon />
          </button>
          <div className="divider"></div>
          <button
            type="button"
            onClick={() => {
              remove(item);
            }}
          >
            <MinusIcon />
          </button>
        </div>
      </div>
    </>
  );
};
const data = [
  {
    img: "/img/1.png",
    title: "Plastic cup",
    subtitle: "water pincher",
    price: "6.50",
    quantity: 2,
    id: "1",
  },
  {
    img: "/img/1.png",
    title: "Plastic jug",
    subtitle: "JUG",
    price: "6.50",
    quantity: 2,
    id: "2",
  },
];

export default CashlessModal;
