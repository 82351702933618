import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CashlessModal from "./CashlessModal";

import {
  addItemToCart,
  addTenItemToCart,
  getCartArray,
  removeItemFromCart,
  emptyWholeCart,
} from "../../redux/cart/reducer";
import {
  BackIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
} from "../../components/Icon";
import {
  BeersArray,
  CocktailsArray,
  FoodArray,
  HotDrinksArray,
  OtherArray,
  SoftsArray,
  WineArray,
  Category,
} from "../../utility/data";
import { Icons } from "../../images/icons";
import CareModal from "./CareModal";

const CashlessPanier = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bailProducts = useLocation().state.bailProducts;
  const { orgId } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [careModalOpen, setCareModalOpen] = React.useState(false);
  const cartStore = useSelector((state) => state.cartReducer.cartArray);
  let total = 0;

  cartStore?.map((i) => {
    total = parseFloat(i?.totalDue + total);
  });

  return (
    <>
      <div className="d-flex flex-column" style={{ minHeight: "100dvh" }}>
        <div className="page-header py-3 position-sticky top-0 zi-50">
          <div className="container">
            <div className="page-title text-start gap-0 py-0">
              <button
                type="button"
                className="back-icon position-relative"
                onClick={() => navigate(`/cashless/${orgId}`)}
                style={{
                  transform: "translate(0)",
                }}
              >
                <BackIcon />
              </button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ marginLeft: "8px" }}>Panier</div>
                <button
                  type="button"
                  className="back-icon position-relative"
                  onClick={() => {
                    dispatch(emptyWholeCart());
                    navigate(-1);
                  }}
                  style={{
                    transform: "translate(0)",
                  }}
                >
                  <TrashIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4 px-20px">
          {cartStore.map((item, index) => (
            <CartItem
              addToCart={(item) => dispatch(addItemToCart(item))}
              removeFromCart={(item) => dispatch(removeItemFromCart(item))}
              key={item.id}
              item={item}
            />
          ))}
        </div>
        <div className="mt-auto position-sticky bottom-0 zi-50">
          <div className="page-header pt-3 pb-4">
            <div className="container">
              <div className="total--price mt-0 mb-12px">
                <span>Total</span>
                <span style={{ color: "#27AE60" }}>{total.toFixed(2)}</span>
              </div>
              <button
                type="button"
                className="submitBtn submitBtn2 m-0"
                onClick={() => {
                  if (bailProducts.length > 0) {
                    setModalOpen(true);
                  } else {
                    navigate(`/cashless/${orgId}/payment`);
                  }
                }}
              >
                PAYER
              </button>
            </div>
          </div>
        </div>
      </div>
      <CashlessModal
        open={modalOpen}
        setOpen={setModalOpen}
        total={total}
        bails={bailProducts}
        setCareModalOpen={setCareModalOpen}
      />
      <CareModal open={careModalOpen} setOpen={setCareModalOpen} />
    </>
  );
};

const CartItem = ({ item, addToCart, removeFromCart }) => {
  return (
    <>
      <div className="cashless-item cart-item">
        <div className="img">
          <img
            src={
              item?.customIcon
                ? item.category === "Food"
                  ? FoodArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Softs"
                  ? SoftsArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Beers"
                  ? BeersArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Cocktails"
                  ? CocktailsArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Wine"
                  ? WineArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Hot drinks"
                  ? HotDrinksArray.find((i) => i.name === item?.customIcon).icon
                  : item.category === "Other" &&
                    OtherArray.find((i) => i.name === item?.customIcon).icon
                : item.category == "Food"
                ? Icons.food
                : item.category == "Softs"
                ? Icons.soft
                : item.category == "Beers"
                ? Icons.beer
                : item.category == "Cocktails"
                ? Icons.cocktail
                : item.category == "Hot drinks"
                ? Icons.drink
                : item.category == "Other" && Icons.others
            }
            alt="item"
          />
        </div>
        <div className="content">
          <p className="subtext">{item.subtitle}</p>
          <h3 className="subtitle">{item.title}</h3>
          <div className="price">
            <span>x{item.quantity}</span>
            <span style={{ color: "#27AE60" }}>
              {(item.price * item.quantity).toFixed(2)}€
            </span>
          </div>
        </div>
        <div className="button-group">
          <button
            type="button"
            onClick={() => {
              addToCart(item);
            }}
          >
            <PlusIcon />
          </button>
          <div className="divider"></div>
          <button
            type="button"
            onClick={() => {
              removeFromCart(item);
            }}
          >
            <MinusIcon />
          </button>
        </div>
      </div>
    </>
  );
};

const data = [
  {
    img: "/img/menu/1.svg",
    title: "Plastic cup",
    subtitle: "water pincher",
    price: "6.50",
    quantity: 2,
    id: "1",
  },
  {
    img: "/img/menu/2.svg",
    title: "Plastic jug",
    subtitle: "JUG",
    price: "6.50",
    quantity: 2,
    id: "2",
  },
  {
    img: "/img/menu/3.svg",
    title: "Plastic cup",
    subtitle: "water pincher",
    price: "6.50",
    quantity: 2,
    id: "3",
  },
  {
    img: "/img/menu/4.svg",
    title: "Plastic jug",
    subtitle: "JUG",
    price: "6.50",
    quantity: 2,
    id: "4",
  },
  {
    img: "/img/menu/5.svg",
    title: "Plastic cup",
    subtitle: "water pincher",
    price: "6.50",
    quantity: 2,
    id: "5",
  },
  {
    img: "/img/menu/6.svg",
    title: "Plastic jug",
    subtitle: "JUG",
    price: "6.50",
    quantity: 2,
    id: "6",
  },
];
export default CashlessPanier;
