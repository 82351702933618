import ENV from "../env";

const updateOrder = async (orgId, orderId) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/organization/${orgId}/order/${orderId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const resData = await response.text();

  if (!response.ok) {
    console.error("Update order failed: " + resData);
    throw new Error("Update order failed: " + resData);
  }

  console.log("Order updated successfully");
};

export default updateOrder;
