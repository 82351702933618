import React from "react";
import { Modal } from "react-bootstrap";
import { ClearIcon } from "../../components/Icon";

const CareModal = ({ open, setOpen }) => {
	return (
		<>
			<Modal
				onHide={setOpen}
				show={open}
				centered
				className="cashless-modal"
			>
				<Modal.Body>
					<div className="text-end">
						<button
							type="button"
							className="no-gutter"
							onClick={() => setOpen(!open)}
						>
							<ClearIcon />
						</button>
					</div>
					<h2 className="title">Montant min. 10€</h2>
					<div className="mb-40 text-center mx-auto font-medium text-8BA4BF max-w-303px">
						Pour les achats en ligne, le montant minimum est de 10€.
					</div>
					<button
						type="button"
						className="submitBtn submitBtn2"
						onClick={() => setOpen(!open)}
					>
						Retour
					</button>
					<div className="pb-2"></div>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default CareModal;
