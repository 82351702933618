import React from "react";
import Lottie from "react-lottie";
import animationData from "../lotties/beer.json";

const DrinkSpinner = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={props.height}
      width={props.width}
    />
  );
};

export default DrinkSpinner;
