import ENV from "../env";

const createParticipant = async (eventId, tickets, details, subdivision) => {
  const response = await fetch(`${ENV.firebaseApiUrl}/participant/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      createdAt: new Date().valueOf().toString(),
      eventId,
      tickets,
      details,
      paid: false,
      subdivision,
    }),
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create participant failed: " + resData.message);
  }

  return resData.participantId;
};

export default createParticipant;
