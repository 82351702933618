import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

import Banner from "../components/Banner";
import Checkout from "../components/Checkout";
import Footer from "../components/Footer";
import Header from "../components/Header";

const EventTicketing = () => {
  const { eventId, participantId, paymentStatus } = useParams();
  const [event, setEvent] = useState();
  const [participant, setParticipant] = useState();

  const loadEvent = async () => {
    const eventRef = doc(db, "Events", eventId);
    let loadedEvent;

    await getDoc(eventRef)
      .then((snapshot) => {
        const data = {
          id: snapshot.id,
          ...snapshot.data(),
        };
        loadedEvent = data;
      })
      .catch((err) => {
        console.log("Fetch event failed: " + err);
      });
    setEvent(loadedEvent);
  };

  const loadParticipant = async () => {
    const participantRef = doc(
      db,
      "Events",
      eventId,
      "Participants",
      participantId
    );
    let loadedParticipant;

    await getDoc(participantRef)
      .then((snapshot) => {
        const data = {
          id: snapshot.id,
          ...snapshot.data(),
        };
        loadedParticipant = data;
      })
      .catch((err) => {
        console.log("Fetch participant failed: " + err);
      });
    setParticipant(loadedParticipant);
  };

  useEffect(() => {
    if (eventId.length) {
      loadEvent();
      loadParticipant();
    }
  }, [eventId]);

  return (
    <>
      <Header />
      <Banner event={event} />
      {event && <Checkout event={event} participant={participant} paymentStatus={paymentStatus}/>}
      {event && <Footer />}
    </>
  );
};

export default EventTicketing;
