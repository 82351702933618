import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

import Banner from "../components/Banner";
import EventDesc from "../components/EventDesc";
import Footer from "../components/Footer";
import Header from "../components/Header";

const EventTicketing = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState();

  const loadEvent = async () => {
    const eventRef = doc(db, "Events", eventId);
    let loadedEvent;

    await getDoc(eventRef)
      .then((snapshot) => {
        const data = {
          id: snapshot.id,
          ...snapshot.data(),
        };
        loadedEvent = data;
      })
      .catch((err) => {
        console.log("Fetch event failed: " + err);
      });

    console.log("Event name= " + loadedEvent.name);

    setEvent(loadedEvent);
  };

  useEffect(() => {
    if (eventId.length) {
      loadEvent();
    }
  }, [eventId]);

  return (
    <>
      <Header />
      <Banner event={event} />
      {event && <EventDesc event={event} />}
      {event && <Footer />}
    </>
  );
};

export default EventTicketing;
