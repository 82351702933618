import ENV from "../env";

const createCashlessPayment = async (
  amount,
  products,
  customerEmail,
  orgId
) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/stripe/create-cashless-session`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount,
        products,
        customerEmail,
        orgId,
        paymentMethods: ["card", "bancontact"],
        checkoutUrl: `${ENV.hostname}/cashless/${orgId}`,
      }),
    }
  );

  const resData = await response.json();
  if (!response.ok) {
    throw new Error("Paiement échoué: " + resData.message);
  }

  const session = resData.session;
  //var extCheckoutUrl = checkoutUrl.replace("https://", "https://ext.");//should be removed for prod

  window.open(session.url, "_parent", "noreferrer");
};

export default createCashlessPayment;
