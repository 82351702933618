import { combineReducers } from "redux";

import userReducer from "./users/reducer";
import cartReducer from "./cart/reducer";

const reducers = combineReducers({
  userReducer,
  cartReducer,
});

export default reducers;
