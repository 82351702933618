import React, { useState, useEffect } from "react";
import { doc, collection, getDocs, addDoc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useAlert } from "react-alert";
import { db, auth } from "../firebase";

import Input from "./Input";
import Dropdown from "./Dropdown";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  createStripeAccount,
  createUserStore,
  createOrganization,
} from "../utility";

const INITIAL_STATE = {
  Firstname: "",
  Name: "",
  BirthDate: new Date(),
  PhoneNumber: "",
  Email: "",
  Password: "",
  RepeatPassword: "",
  Street: "",
  StreetNumber: "",
  City: "",
  PostalCode: "",
  Organization: "",
  Activity: "",
  Url: "",
  BankAccount: "",
};

const SubscribeForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(INITIAL_STATE);

  const alert = useAlert();

  const getAge = (date) => {
    const diff = new Date() - date.getTime();
    const age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  };

  const saveForm = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (getAge(new Date(form.BirthDate)) < 18) {
      alert.show(
        "Vous devez avoir plus de 18 ans pour vendre vos billets en ligne!",
        {
          type: "error",
        }
      );
    } else if (form.Password.length <= 6) {
      alert.show("Mot de passe trop court!", { type: "error" });
    } else if (form.Password !== form.RepeatPassword) {
      alert.show("Les mots de passes ne correspondent pas!", { type: "error" });
    } else if (!form.PhoneNumber.startsWith("+")) {
      alert.show(
        "Veuillez entrer votre numéro de téléphone avec le préfix! (EX: +32)",
        { type: "error" }
      );
    } else if (!form.Email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      alert.show("Format d'e-mail invalide!", { type: "error" });
    } else {
      await createUserWithEmailAndPassword(auth, form.Email, form.Password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;

          const orgId = await createOrganization({
            organization: form.Organization,
            activity: form.Activity,
            logo: null,
            createdAt: new Date().getTime().toString(),
            uid: user.uid,
            isDataUpdated: true,
            cashierUid: [],
            adminUid: [user.uid],
            adminEmail: [],
            cashierEmail: [],
            bankAccount: form.BankAccount,
            stripeId: null,
            liquidityAmount: 0,
            cashRefused: false,
          });

          if (!orgId) {
            alert.show(
              "Inscription incomplète! Veuillez contacter le support.",
              {
                type: "error",
              }
            );
          }

          console.log("Organization created!");

          const storeId = await createUserStore({
            uid: user.uid,
            email: form.Email,
            username: form.Firstname,
            type: "admin",
            activeOrg: orgId,
            createAt: new Date().getTime().toString(),
            birthDate: form.BirthDate,
            address: `${form.Street} ${form.StreetNumber}, ${form.PostalCode} ${form.City}`,
          });

          if (!storeId) {
            alert.show(
              "Inscription incomplète! Veuillez contacter le support.",
              {
                type: "error",
              }
            );
          }

          const stripeActivated = await createStripeAccount(form, null, orgId);

          if (stripeActivated) {
            alert.show("Inscription réussie!", { type: "success" });
          } else {
            alert.show(
              "Inscription incomplète! Veuillez contacter le support.",
              {
                type: "error",
              }
            );
          }

          setForm(INITIAL_STATE);
        })
        .catch((error) => {
          console.log(error);
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          var message = "";
          switch (errorCode) {
            case "auth/email-already-in-use":
              message =
                "L'adresse email est déjà utilisée par un autre utilisateur.";
              break;
            case "auth/invalid-email":
              message = "Adresse email invalide.";
              break;
            case "auth/operation-not-allowed":
              message = "Cette opération n'est pas autorisée.";
              break;
            case "auth/weak-password":
              message = "Mot de passe trop faible.";
              break;
            default:
              message = "Veuillez réessayer.";
          }
          alert.show("L'inscription a échouée! " + message, {
            type: "error",
          });
        });
    }
    setIsLoading(false);
  };

  const inputChangeHandler = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const checkoutHandler = async () => {};

  return (
    <section className="event-desc-section">
      <div className="container">
        <div className="section-header">
          <h2 className="title">Vendez vos tickets en ligne</h2>
          <div className="text">
            Remplissez ce formulaire pour vous inscire sur Comitty et fournir
            les informations nécessaires pour commencer à vendre vos tickets en
            ligne.
          </div>
        </div>
        <div className="__details-wrapper">
          <form className="__subscribe-form" onSubmit={saveForm}>
            <h3 className="__details-form-title">À propos de vous</h3>
            <div className="row g-2 g-lg-3">
              <div className="col-6">
                <Input
                  placeholder="Ajouter Prénom"
                  label="Prénom"
                  type="text"
                  id="Firstname"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.Firstname}
                />
              </div>
              <div className="col-6">
                <Input
                  placeholder="Ajouter Nom"
                  label="Nom"
                  type="text"
                  id="Name"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.Name}
                />
              </div>
              <div className="col-6">
                <Input
                  placeholder="Date de naissance"
                  label="Date de naissance"
                  type="date"
                  id="BirthDate"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.BirthDate}
                />
              </div>
              <div className="col-6">
                <label
                  className="form-label __form-label"
                  htmlFor="phone number"
                >
                  numéro de téléphone
                </label>
                <div className="d-flex" style={{ gap: "15px" }}>
                  <input
                    className="form-control __form-control w-0 flex-grow"
                    placeholder="+32 470 000 000"
                    type="text"
                    id="PhoneNumber"
                    required="required"
                    onChange={(e) => inputChangeHandler(e)}
                    value={form.PhoneNumber}
                  />
                </div>
              </div>
              <div className="col-12">
                <Input
                  placeholder="@mail.com"
                  label="adresse e-mail"
                  type="text"
                  id="Email"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.Email}
                />
                <div className="text-sm mt-2">
                  <span className="me-1">
                    <svg
                      width="10"
                      height="11"
                      viewBox="0 0 10 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 5.05285L9.43152 3.12873L5.83979 4.48374L5.96899 0.5H4.05685L4.16021 4.45664L0.594315 3.12873L0 5.05285L3.64341 6.16396L1.31783 9.30759L2.86822 10.5L5.01292 7.16667L7.15762 10.5L8.70801 9.30759L6.38243 6.16396L10 5.05285Z"
                        fill="#EB5757"
                      />
                    </svg>
                  </span>
                  Attention : Votre adresse e-mail vous servira d'identifiant
                  sur Comitty
                </div>
              </div>
              <div className="col-6">
                <Input
                  placeholder="Mot de passe"
                  label="Mot de passe"
                  type="password"
                  id="Password"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.Password}
                />
              </div>
              <div className="col-6">
                <Input
                  placeholder="Répéter mot de passe"
                  label="Répéter Mot de Passe"
                  type="password"
                  id="RepeatPassword"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.RepeatPassword}
                />
              </div>
              <div className="col-6">
                <Input
                  placeholder="Rue"
                  label="Rue"
                  type="text"
                  id="Street"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.Street}
                />
              </div>
              <div className="col-6">
                <Input
                  placeholder="Numéro"
                  label="Numéro"
                  type="text"
                  id="StreetNumber"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.StreetNumber}
                />
              </div>
              <div className="col-6">
                <Input
                  placeholder="Ville"
                  label="Ville"
                  type="text"
                  id="City"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.City}
                />
              </div>
              <div className="col-6">
                <Input
                  placeholder="Code postal"
                  label="Code Postal"
                  type=" number"
                  id="PostalCode"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.PostalCode}
                />
              </div>
              <h3 className="__details-form-subtitle">
                À propos de votre organisation
              </h3>
              <div className="col-6">
                <Input
                  placeholder="Ajouter Nom"
                  label="Nom de votre organisation"
                  type="text"
                  id="Organization"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.Organization}
                />
              </div>
              <div className="col-6">
                <Input
                  placeholder="Club de jeune, sportif, scholaire, ..."
                  label="Secteur d'activité"
                  type="text"
                  id="Activity"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.Activity}
                />
              </div>
              <div className="col-12">
                <Input
                  placeholder="https://..."
                  label="URL de votre site web ou page Facebook"
                  type="text"
                  id="Url"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.Url}
                />
              </div>
              <div className="col-12">
                <Input
                  placeholder="BE..."
                  label="Numéro de compte bancaire"
                  type="text"
                  id="BankAccount"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                  value={form.BankAccount}
                />
                <div className="text-sm mt-2">
                  <span className="me-1">
                    <svg
                      width="10"
                      height="11"
                      viewBox="0 0 10 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 5.05285L9.43152 3.12873L5.83979 4.48374L5.96899 0.5H4.05685L4.16021 4.45664L0.594315 3.12873L0 5.05285L3.64341 6.16396L1.31783 9.30759L2.86822 10.5L5.01292 7.16667L7.15762 10.5L8.70801 9.30759L6.38243 6.16396L10 5.05285Z"
                        fill="#EB5757"
                      />
                    </svg>
                  </span>
                  Attention : Entrez le numéro de compte sur lequel vous
                  souhaitez que l'argent de vos ventes soit reversé
                </div>
              </div>
              <div className="col-12">
                {isLoading ? (
                  <LoadingSpinner height="100%" width="15%">
                    Patience
                  </LoadingSpinner>
                ) : (
                  <button type="submit" className="submitBtn">
                    Confirmer
                  </button>
                )}
                <div className="text-sm mt-2">
                  <span className="me-1">
                    <svg
                      width="10"
                      height="11"
                      viewBox="0 0 10 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 5.05285L9.43152 3.12873L5.83979 4.48374L5.96899 0.5H4.05685L4.16021 4.45664L0.594315 3.12873L0 5.05285L3.64341 6.16396L1.31783 9.30759L2.86822 10.5L5.01292 7.16667L7.15762 10.5L8.70801 9.30759L6.38243 6.16396L10 5.05285Z"
                        fill="#EB5757"
                      />
                    </svg>
                  </span>
                  Attention : En vous inscrivant vous acceptez les conditions
                  d'utilisation de Comitty
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SubscribeForm;
