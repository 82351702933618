import React from "react";

import HomeBanner from "../components/HomeBanner";
import NotFound from "../components/NotFound";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Politics = () => {


  return (
    <>
      <Header />
      <NotFound/>
      <Footer />
    </>
  );
};

export default Politics;
