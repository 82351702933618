import React from "react";

const Input = ({ label, placeholder, ...rest }) => {
	return (
		<>
			<label className="form-label __form-label" htmlFor={label}>
				{label}
			</label>
			<input
				className="form-control __form-control"
				placeholder={placeholder}
				{...rest}
			/>
		</>
	);
};

export default Input;
