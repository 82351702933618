import ENV from "../env";

const getAds = async (type) => {
  const response = await fetch(`${ENV.firebaseApiUrl}/advertisement/${type}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Get ad failed: " + resData.message);
  }

  return resData;
};

export default getAds;
