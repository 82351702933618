import ENV from "../env";

const createStripePayment = async (
  amount,
  tickets,
  details,
  customerEmail,
  orgId,
  eventId,
  paymentMethod,
  feeDelegated,
  subdivision
) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/stripe/create-checkout-session`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: amount,
        eventId,
        tickets,
        details,
        customerEmail,
        paymentMethod,
        feeDelegated,
        orgId,
        checkoutUrl: `${ENV.hostname}/checkout/${eventId}`,
        subdivision,
      }),
    }
  );

  const resData = await response.json();
  if (!response.ok) {
    throw new Error("Paiement échoué: " + resData.message);
  }

  const session = resData.session;
  //var extCheckoutUrl = checkoutUrl.replace("https://", "https://ext.");//should be removed for prod

  window.open(session.url, "_parent", "noreferrer");
};

export default createStripePayment;
