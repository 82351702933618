import React from 'react'
import { Blocks } from  'react-loader-spinner'

export const Loader = ({loader}) => {
  return (
    <div className="blockLoader">
        <Blocks
        visible={loader}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        />
      </div>
  )
}
export const Loader2 = ({loader}) => {
  return (
    <div className="blockLoader2">
        <div className="loderInner">
            <Blocks
            visible={loader}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            />
        </div>
      </div>
  )
}
