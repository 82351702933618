import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useAlert } from "react-alert";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/mousewheel";
import { FreeMode, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import Modal from "react-bootstrap/Modal";

import { db } from "../../firebase";
import Dropdown from "../Dropdown";
import Input from "../Input";
import { ReactComponent as AngleLeft } from "../../images/AngleLeft.svg";
import { ReactComponent as DeleteIcon } from "../../images/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../images/EditIcon.svg";
import { ReactComponent as Plus } from "../../images/Plus.svg";
import { ReactComponent as Beer } from "../../images/Beers.svg";
import { ReactComponent as Softs } from "../../images/Softs.svg";
import { ReactComponent as Food } from "../../images/Fast-food.svg";
import { ReactComponent as HotDrinks } from "../../images/hotDrinks.svg";
import { ReactComponent as Cocktails } from "../../images/Cocktails.svg";
import { ReactComponent as Wine } from "../../images/Wine.svg";
import { ReactComponent as Others } from "../../images/Others.svg";
import { Icons } from "../../images/icons";
import {
  BeersArray,
  CocktailsArray,
  FoodArray,
  HotDrinksArray,
  OtherArray,
  SoftsArray,
  WineArray,
} from "../../utility/data";
import "./AddProduct.scss";
import PosIconModal from "./PosIconModal";

const AddProduct = ({ orgId, selectedProduct, setAddNew }) => {
  const { t } = useTranslation("editproduct");
  const userStore = useSelector((state) => state.userReducer);

  const [selectedCategory, setSelectedCategory] = useState("Beers");
  const [secondaryColor, setSecondaryColor] = useState(false);
  const [uploadIcon, setUploadIcon] = useState(icons[0]); // Ensure icons is defined somewhere in your code
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [data, setData] = useState();

  const [loader, setLoader] = useState(false);
  const [outOfStock, setOutOfStock] = useState(false);
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [positifNumber, setPositifNumber] = useState(true);
  const [selectedColor, setSelectedColor] = useState("");
  const [numberOfTicket, setNumberOfTicket] = useState(0);
  const [selectedSecondColor, setSelectedSecondColor] = useState("");
  const [numberOfSecondTicket, setNumberOfSecondTicket] = useState(0);
  const [customIcon, setCustomIcon] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");

  // Reset all state variables when selectedProduct changes
  useEffect(() => {
    setSelectedCategory(selectedProduct?.category || "Beers");
    setSecondaryColor(selectedProduct?.secondTicketColor || false);
    setUploadIcon(selectedProduct ? selectedProduct.customIcon : icons[0]); // Assuming icons[0] is a default icon
    setOutOfStock(selectedProduct?.outOfStock || false);
    setTitle(selectedProduct?.title || "");
    setPrice(selectedProduct?.price || "");
    setSelectedColor(selectedProduct?.ticketColor || "");
    setNumberOfTicket(selectedProduct?.ticketNumber || 0);
    setSelectedSecondColor(selectedProduct?.secondTicketColor || "");
    setNumberOfSecondTicket(selectedProduct?.secondTicketNumber || 0);
    setCustomIcon(selectedProduct?.customIcon || "");
    setAvailableQuantity(selectedProduct?.availableQuantity || "");
  }, [selectedProduct]);

  const alert = useAlert();

  const saveProduct = async () => {
    if (title === "") {
      alert.show(t("choose_name"), {
        type: "warning",
      });
    } else if (price === "") {
      alert.show(t("price_required"), {
        type: "warning",
      });
    } else if (!/^-?\d*(\.\d+)?$/.test(price)) {
      alert.show("Veuillez entrer un prix valide.", {
        type: "warning",
      });
    } else if (selectedCategory == null) {
      alert.show("Choisissez une catégorie!", {
        type: "warning",
      });
    } else {
      setLoader(true);
      const id = selectedProduct?.id
        ? selectedProduct.id
        : new Date().getTime().toString();

      const productData = {
        title,
        price,
        customIcon,
        category: selectedCategory,
        id,
        ticketColor: selectedColor,
        ticketNumber: positifNumber
          ? Math.abs(numberOfTicket)
          : -Math.abs(numberOfTicket),
        secondTicketColor: selectedSecondColor,
        secondTicketNumber: positifNumber
          ? Math.abs(numberOfSecondTicket)
          : -Math.abs(numberOfSecondTicket),
        uid: userStore?.users.uid,
        orgId,
        outOfStock,
        availableQuantity: availableQuantity || 0,
      };
      try {
        const docRef = doc(db, "products", id); // Create a reference to the document
        await setDoc(docRef, productData, { merge: true }); // Set the document with merge option true
        setLoader(false);
        setAddNew(false);
        alert.show("Produit enregistré avec succès.", {
          type: "success",
        });
      } catch (error) {
        console.log("Error writing document: ", error);
        setLoader(false);
        alert.show("Quelque chose n'a pas marché.", {
          type: "error",
        });
      }
    }
  };

  const handleEditIcon = (icon) => {
    setData(
      data.map((item) =>
        item.id == editItem.id ? { ...item, img: icon.img } : item
      )
    );
  };

  const handleSelectCategory = (ctg) => {
    setSelectedCategory(ctg);
    setCustomIcon("");
  };

  return (
    <div className="amountMainCard themeCard right-side-bar-content">
      <h4 className="add-product-title" onClick={() => setAddNew(false)}>
        <AngleLeft /> <div className="w-0 flex-grow-1">{t("return")}</div>
      </h4>
      <div className="item">
        <h5 className="subtitle">{t("the_product_is")}</h5>
        <div className="mb-28 gap-12px d-flex">
          <button
            type="button"
            className={`themeBtn m-0 w-100 pos-submit-btn ${
              outOfStock ? "btn-outline-success" : "btn-success"
            }`}
            onClick={() => setOutOfStock(false)}
          >
            {t("in_stock")}
          </button>
          <button
            type="button"
            className={`themeBtn m-0 w-100 pos-submit-btn ${
              outOfStock ? "btn-danger" : "btn-outline-danger"
            }`}
            onClick={() => setOutOfStock(true)}
          >
            {t("out_of_stock")}
          </button>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="mb-20">
            <Input
              label={t("title")}
              placeholder={t("product_name")}
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required="required"
            />
          </div>
          <div className="mb-20">
            <Input
              label={t("price")}
              placeholder="5.99"
              id="price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required="required"
            />
          </div>
          <div className="mb-20">
            <Input
              label={t("number_in_stock")}
              placeholder="500"
              id="quantity"
              type="number"
              value={availableQuantity}
              onChange={(e) => setAvailableQuantity(e.target.value)}
            />
          </div>
          <div className="mb-20">
            <label className="form-label __form-label">{t("category")}</label>
            <div className="filterRow">
              <div
                className={
                  selectedCategory == "Beers" ? "filBtn active" : "filBtn"
                }
                onClick={() => handleSelectCategory("Beers")}
              >
                <Beer />
                <span>{t("beers")}</span>
              </div>
              <div
                className={
                  selectedCategory == "Softs" ? "filBtn active" : "filBtn"
                }
                onClick={() => handleSelectCategory("Softs")}
              >
                <Softs />
                <span>{t("softs")}</span>
              </div>
              <div
                className={
                  selectedCategory == "Food" ? "filBtn active" : "filBtn"
                }
                onClick={() => handleSelectCategory("Food")}
              >
                <Food />
                <span>{t("food")}</span>
              </div>
              <div
                className={
                  selectedCategory == "Cocktails" ? "filBtn active" : "filBtn"
                }
                onClick={() => handleSelectCategory("Cocktails")}
              >
                <Cocktails />
                <span>{t("cocktails")}</span>
              </div>
              <div
                className={
                  selectedCategory == "Wine" ? "filBtn active" : "filBtn"
                }
                onClick={() => handleSelectCategory("Wine")}
              >
                <Wine />
                <span>{t("wine")}</span>
              </div>
              <div
                className={
                  selectedCategory == "Hot drinks" ? "filBtn active" : "filBtn"
                }
                onClick={() => handleSelectCategory("Hot drinks")}
              >
                <HotDrinks />
                <span>{t("hot_drinks")}</span>
              </div>
              <div
                className={
                  selectedCategory == "Other" ? "filBtn active" : "filBtn"
                }
                onClick={() => handleSelectCategory("Other")}
              >
                <Others />
                <span>{t("others")}</span>
              </div>
            </div>
          </div>
          <TokenColor
            label={t("color_token")}
            positifNumber={positifNumber}
            setPositifNumber={setPositifNumber}
            numberOfTicket={numberOfTicket}
            setNumberOfTicket={setNumberOfTicket}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <div className="py-4 d-flex justify-content-between align-items-center border-y mb-4">
            <h5 className="subtitle m-0">{t("second_color")}</h5>
            <Form.Check
              type="switch"
              checked={secondaryColor}
              onChange={(e) => setSecondaryColor(e.target.checked)}
            />
          </div>
          {secondaryColor && (
            <TokenColor
              label={t("choose_second_color")}
              positifNumber={positifNumber}
              setPositifNumber={setPositifNumber}
              numberOfTicket={numberOfSecondTicket}
              setNumberOfTicket={setNumberOfSecondTicket}
              selectedColor={selectedSecondColor}
              setSelectedColor={setSelectedSecondColor}
            />
          )}
          <label className="form-label __form-label">{t("optionnal")}</label>
          <div className="mb-4">
            <div
              className="productCard max-w-200 mx-auto cursor-pointer"
              onClick={() => setEditModalOpen(true)}
            >
              <span className="color"></span>
              <div className="productImg">
                <img
                  src={
                    customIcon
                      ? selectedCategory === "Food"
                        ? FoodArray.find((i) => i.name === customIcon).icon
                        : selectedCategory === "Softs"
                        ? SoftsArray.find((i) => i.name === customIcon).icon
                        : selectedCategory === "Beers"
                        ? BeersArray.find((i) => i.name === customIcon).icon
                        : selectedCategory === "Cocktails"
                        ? CocktailsArray.find((i) => i.name === customIcon).icon
                        : selectedCategory === "Wine"
                        ? WineArray.find((i) => i.name === customIcon).icon
                        : selectedCategory === "Hot drinks"
                        ? HotDrinksArray.find((i) => i.name === customIcon).icon
                        : selectedCategory === "Other" &&
                          OtherArray.find((i) => i.name === customIcon).icon
                      : selectedCategory == "Food"
                      ? Icons.food
                      : selectedCategory == "Softs"
                      ? Icons.soft
                      : selectedCategory == "Beers"
                      ? Icons.beer
                      : selectedCategory == "Cocktails"
                      ? Icons.cocktail
                      : selectedCategory == "Wine"
                      ? Icons.wine
                      : selectedCategory == "Hot drinks"
                      ? Icons.drink
                      : selectedCategory == "Other" && Icons.others
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="themeBtn m-0 w-100 pos-submit-btn"
            onClick={saveProduct}
          >
            {t("save")}
          </button>
        </form>
        <PosIconModal
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          customIcon={customIcon}
          setCustomIcon={setCustomIcon}
          selectedCategory={selectedCategory}
        />
      </div>
    </div>
  );
};

const TokenColor = ({
  label,
  positifNumber,
  setPositifNumber,
  numberOfTicket,
  setNumberOfTicket,
  selectedColor,
  setSelectedColor,
}) => {
  const { t } = useTranslation("editproduct");
  const handleColorClick = (color) => {
    if (color === selectedColor) {
      setNumberOfTicket(numberOfTicket + 1);
    } else {
      setSelectedColor(color);
      setNumberOfTicket(1);
    }
  };
  return (
    <>
      {label && <label className="form-label __form-label">{label}</label>}
      <div className="mb-20 gap-12px d-flex">
        <button
          type="button"
          className="themeBtn m-0 w-100 pos-submit-btn btn-dark"
        >
          {`${t("number")}: ${positifNumber ? "+" : "-"}${numberOfTicket}`}
        </button>
        <button
          type="button"
          className={`themeBtn m-0 w-100 pos-submit-btn ${
            positifNumber ? "btn-success" : "btn-negative"
          }`}
          onClick={() => setPositifNumber(!positifNumber)}
        >
          {positifNumber ? t("positive") : t("negative")}
        </button>
      </div>
      <div className="colors-wrapper overflow-hidden">
        <Swiper
          slidesPerView="auto"
          modules={[Mousewheel]}
          spaceBetween={10}
          mousewheel={{
            invert: false,
            forceToAxis: true, // Ensures that the mousewheel scrolls are strictly horizontal
          }}
          direction="horizontal" // Explicitly set the direction to horizontal
        >
          {colors.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="color"
                style={{ backgroundColor: item.color }}
                onClick={() => handleColorClick(item.color)}
              >
                {numberOfTicket > 0 && item.color === selectedColor && (
                  <span className="counter">{numberOfTicket}</span>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

const icons = [
  {
    img: "/img/products/4.png",
    id: "1",
  },
  {
    img: "/img/products/1.png",
    id: "2",
  },
  {
    img: "/img/products/2.png",
    id: "3",
  },
  {
    img: "/img/products/3.png",
    id: "4",
  },
  {
    img: "/img/products/1.png",
    id: "5",
  },
  {
    img: "/img/products/4.png",
    id: "6",
  },
  {
    img: "/img/products/1.png",
    id: "7",
  },
  {
    img: "/img/products/2.png",
    id: "8",
  },
  {
    img: "/img/products/3.png",
    id: "9",
  },
  {
    img: "/img/products/1.png",
    id: "10",
  },
];
const colors = [
  {
    color: "#ed0000",
    id: "1",
  },
  {
    color: "#F44336",
    id: "2",
  },
  {
    color: "#E91E63",
    id: "3",
  },
  {
    color: "#9C27B0",
    id: "4",
  },
  {
    color: "#673AB7",
    id: "5",
  },
  {
    color: "#3F51B5",
    id: "6",
  },
  {
    color: "#2196F3",
    id: "7",
  },
  {
    color: "#03A9F4",
    id: "8",
  },
  {
    color: "#00BCD4",
    id: "9",
  },
  {
    color: "#009688",
    id: "10",
  },
  {
    color: "#4CAF50",
    id: "11",
  },
  {
    color: "#8BC34A",
    id: "12",
  },
  {
    color: "#CDDC39",
    id: "13",
  },
  {
    color: "#FFEB3B",
    id: "14",
  },
  {
    color: "#FFC107",
    id: "15",
  },
  {
    color: "#FF9800",
    id: "16",
  },
  {
    color: "#FF5722",
    id: "17",
  },
  {
    color: "#795548",
    id: "18",
  },
  {
    color: "#9E9E9E",
    id: "19",
  },
  {
    color: "#607D8B",
    id: "20",
  },
  {
    color: "#000000",
    id: "21",
  },
];

export default AddProduct;
