import React from "react";

import HomeBanner from "../components/HomeBanner";
import SubscribeForm from "../components/SubscribeForm";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Subscribe = () => {

  return (
    <>
      <Header />
      <HomeBanner title={"Inscription"}/>
      <SubscribeForm />
      <Footer />
    </>
  );
};

export default Subscribe;
