import ENV from "../env";

const getOrgOrder = async (orgId, orderId) => {

  const response = await fetch(
    `${ENV.firebaseApiUrl}/organization/${orgId}/order/${orderId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const resData = await response.json();

  if (!response.ok) {
    console.error("Get organization's order failed: " + resData.message);
    throw new Error("Get organization's order failed: " + resData.message);
  }

  return resData;
};

export default getOrgOrder;
