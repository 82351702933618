import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../images/CloseIcon.svg";
import {
  BeersArray,
  CocktailsArray,
  FoodArray,
  HotDrinksArray,
  OtherArray,
  SoftsArray,
  WineArray,
} from "../../utility/data";

const PosIconModal = ({
  modalOpen,
  setModalOpen,
  customIcon,
  setCustomIcon,
  selectedCategory,
}) => {
  const [dummyItem, setDummyItem] = useState(customIcon);
  const [icons, setIcons] = useState([]);
  const { t } = useTranslation("editproduct");

  useEffect(() => {
    switch (selectedCategory) {
      case "Beers":
        setIcons(BeersArray);
        break;
      case "Cocktails":
        setIcons(CocktailsArray);
        break;
      case "Food":
        setIcons(FoodArray);
        break;
      case "Hot drinks":
        setIcons(HotDrinksArray);
        break;
      case "Other":
        setIcons(OtherArray);
        break;
      case "Softs":
        setIcons(SoftsArray);
        break;
      case "Wine":
        setIcons(WineArray);
        break;
      default:
        setIcons([]);
    }
  }, [selectedCategory]);

  return (
    <Modal show={modalOpen} className="pos-modal" centered>
      <Modal.Header className="border-0">
        <h5 className="m-0">{t("choose_icon")}</h5>
        <button
          type="button"
          className="no-gutter"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="icons-grid">
          {icons.map((item, index) => (
            <div
              className={`productCard ${
                item.name == customIcon ? "active" : ""
              }`}
              key={index}
              onClick={() => setCustomIcon(item.name)}
            >
              <div className="productImg">
                <img src={item.icon} alt="" />
              </div>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="themeBtn m-0 w-100 pos-submit-btn mt-4"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          {t("confirm")}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default PosIconModal;
