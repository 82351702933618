import React from "react";

import { Link, Text, View, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  image: {
    width: "10%"
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    textTransform: "uppercase",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  name: {
    fontSize: 24,
    fontFamily: "Poppins Bold",
  },
  subtitle: {
    fontSize: 10,
    justifySelf: "flex-end",
    fontFamily: "Poppins",
  },
  link: {
    fontFamily: "Poppins",
    fontSize: 10,
    color: "black",
    textDecoration: "none",
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
});

export default (props) => (
  <View style={styles.container}>
    <View style={styles.detailColumn}>
      {props.title && <Text style={styles.name}>{props.title}</Text>}
      <Text style={styles.subtitle}>Comitty Tickets</Text>
    </View>
    <View style={styles.linkColumn}>
      <Link href="mailto:info@comitty.be" style={styles.link}>
        <Text style={styles.subtitle}>info@comitty.be</Text>
      </Link>
    </View>
  </View>
);
