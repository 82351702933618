import ENV from "../env";

const createOrganization = async (details) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/organization/create`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        org: details,
      }),
    }
  );

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create organization failed: " + resData.message);
  }

  return resData.orgId;
};

export default createOrganization;
