// src/i18n.js
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: "fr", // Fallback language
    supportedLngs: ["en", "fr", "nl"], // List of available languages
    debug: false, // Enable to see logs in the console
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
    react: {
      useSuspense: false, // Set to true if you want to wait for loaded in every translated hoc
    },
  });

export default i18next;
