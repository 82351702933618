import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import Modal from "react-bootstrap/Modal";
import { useAlert } from "react-alert";
import * as XLSX from "xlsx";

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import "./dashboard.css";
import { useMediaQuery } from "react-responsive";

import { db } from "../../../firebase";

import itemSoldImg from "../../../images/itemSold.svg";
import totalRevenueImg from "../../../images/totalRevenue.svg";
import profile1 from "../../../images/profile1.png";

import { ReactComponent as All } from "../../../images/All.svg";
import { ReactComponent as Beer } from "../../../images/Beers.svg";
import { ReactComponent as Softs } from "../../../images/Softs.svg";
import { ReactComponent as Food } from "../../../images/Fast-food.svg";
import { ReactComponent as HotDrinks } from "../../../images/hotDrinks.svg";
import { ReactComponent as Cocktails } from "../../../images/Cocktails.svg";
import { ReactComponent as Wine } from "../../../images/Wine.svg";
import { ReactComponent as Others } from "../../../images/Others.svg";
import { Icons } from "../../../images/icons";
import {
  BeersArray,
  CocktailsArray,
  FoodArray,
  HotDrinksArray,
  OtherArray,
  SoftsArray,
  WineArray,
} from "../../../utility/data";
import food from "../../../images/Fast-food.svg";
import { Loader2 } from "../../../helper/loader";

let now = new Date();
let startHour = 6;

// Set initial start and end dates to the beginning of today
let initialStartDate = new Date();
let initialEndDate = new Date();
initialStartDate.setHours(0, 0, 0, 0);
initialEndDate.setHours(0, 0, 0, 0);

if (now.getHours() < startHour) {
  // Before 6 AM, set the period from 6 AM yesterday to 6 AM today
  initialStartDate.setDate(initialStartDate.getDate() - 1);
  initialStartDate.setHours(startHour, 0, 0, 0);
  initialEndDate.setHours(startHour, 0, 0, 0);
} else {
  // After 6 AM, set the period from 6 AM today to 6 AM tomorrow
  initialStartDate.setHours(startHour, 0, 0, 0);
  initialEndDate.setDate(initialEndDate.getDate() + 1);
  initialEndDate.setHours(startHour, 0, 0, 0);
}

// console.log("Start Date:", initialStartDate);
// console.log("End Date:", initialEndDate);

// import searchIcon from "../../../images/search.svg";
// import calendar from "../../../assets/images/calendar.svg";

// import axios from "axios";

// js

// import Navbar from './navbar';
// import Sidebar from './sidebar';

const exportToXlsx = (
  productData,
  orderData,
  totalRevenue,
  startDate,
  endDate
) => {
  console.log(orderData);
  const formattedData = productData.map((product) => {
    const quantity = orderData.reduce((acc, order) => {
      const productInOrder = order.products.find(
        (p) =>
          p.title === product.title &&
          p.category === product.category &&
          p.price === product.price
      );
      return productInOrder ? acc + productInOrder.quantity : acc; // Fix: Ensure quantity is accumulated correctly
    }, 0);

    const price = product.price || 0; // Ensure price is set to 0 if not available
    const revenue = quantity * price;
    const totalPercentage = totalRevenue ? (revenue / totalRevenue) * 100 : 0;

    return {
      product: product.title,
      number_sold: quantity,
      price: price,
      total_percentage: totalPercentage.toFixed(2), // Limit to two decimals
      revenue: revenue,
    };
  });

  const totalSold = formattedData.reduce(
    (sum, item) => sum + parseInt(item.number_sold),
    0
  );
  const totalRevenueSum = formattedData.reduce(
    (sum, item) => sum + parseFloat(item.revenue),
    0
  );

  formattedData.push({
    product: "All",
    number_sold: totalSold,
    price: null,
    total_percentage: "100.00", // Fixed to always be 100 as a string with two decimals
    revenue: totalRevenueSum,
  });

  var startDate1 = startDate.getTime();
  var endDate1 = endDate.getTime();

  var start = new Date(startDate1);
  start.setHours(start.getHours());

  var end = new Date(endDate1);
  end.setHours(end.getHours() + 24);

  // Format the dates to DD-MM-YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formattedStartDate = formatDate(start);
  const formattedEndDate = formatDate(end);

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

  // Set column widths
  const columnWidths = [
    { wch: 30 }, // Product
    { wch: 15 }, // Number Sold
    { wch: 10 }, // Price
    { wch: 20 }, // Total Percentage
    { wch: 15 }, // Revenue
  ];
  worksheet["!cols"] = columnWidths;

  // Set header row to bold
  const headers = [
    "Product",
    "Number Sold",
    "Price",
    "Total Percentage",
    "Revenue",
  ];
  headers.forEach((header, index) => {
    const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })]; // Get the cell for the header
    if (cell) {
      cell.s = {
        font: {
          bold: true,
        },
      };
    }
  });

  XLSX.writeFile(
    workbook,
    `Revenue_${formattedStartDate}_${formattedEndDate}.xlsx`
  );
};

function Dashboard({
  organizations,
  orgId,
  dateClickHandle,
  setDateRange,
  startDate,
  endDate,
  dateResetHandle,
  paymentMethod,
  setPaymentMethod,
}) {
  const navigate = useNavigate();
  const alert = useAlert();
  const { t } = useTranslation("dashboard");

  const [productData, setProductData] = useState([]);
  const [productDataInitial, setproductDataInitial] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderDataInitial, setOrderDataInitial] = useState([]);
  const [itemSold, setItemSold] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [softRevenue, setsoftRevenue] = useState(0);
  const [hotDrinkRevenue, setHotDrinkRevenue] = useState(0);
  const [beerRevenue, setBeerRevenue] = useState(0);
  const [cocktailRevenue, setCocktailRevenue] = useState(0);
  const [foodRevenue, setFoodRevenue] = useState(0);
  const [wineRevenue, setWineRevenue] = useState(0);
  const [otherRevenue, setOtherRevenue] = useState(0);
  const [loader, setLoader] = useState(false);
  const [liquiditySelected, setLiquiditySelected] = useState({});

  const [filter, setFilter] = useState("All");
  const [submitTimeCount, setSubmitTimeCount] = useState(0);

  const [show, setShow] = useState(false);
  const [reloading, setReloading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getOrgProduct = async () => {
    //setLoader(true);
    let records = [];
    const chunkSize = 10; // Firestore limit for 'in' query
    const orgIdChunks = [];

    // Split orgIds into chunks
    for (let i = 0; i < orgId.length; i += chunkSize) {
      orgIdChunks.push(orgId.slice(i, i + chunkSize));
    }

    for (const chunk of orgIdChunks) {
      const q = query(collection(db, "products"), where("orgId", "in", chunk));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const productData = doc.data();

        // Check if a product with the same title and price already exists
        const exists = records.some(
          (record) =>
            record.title === productData.title &&
            record.price === productData.price &&
            record.category === productData.category
        );

        if (!exists) {
          records.push(productData); // Only add if title and price are unique
        }
      });
    }

    setProductData(records);
    setproductDataInitial(records);
    // setLoader(false);
  };

  const getRevenueData = async () => {
    setLoader(true);
    let records = [];
    const q = query(collection(db, "Revenues"), where("orgId", "in", orgId));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      records.push(doc.data());
    });
    setOrderData(records);
    setOrderDataInitial(records);
    findPDetail(records);
    setLoader(false);
  };

  const findPDetail = (revenueData1) => {
    let findOrder = [];
    let findQantity = 0;
    let findRevenue = 0;

    let findSoftQuantity = 0;
    let findBeerQuantity = 0;
    let findCocktailQuantity = 0;
    let findFoodQuantity = 0;
    let findWineQuantity = 0;
    let findHotDrinksQuantity = 0;
    let findOtherQuantity = 0;

    var revenue = revenueData1.forEach((element) => {
      for (let i = 0; i < element.products.length; i++) {
        //console.log(element.products[i].title)
        findQantity = findQantity + element.products[i].quantity;
        if (element.products[i].category === "Softs") {
          findSoftQuantity = findSoftQuantity + element.products[i].totalDue;
        }
        if (element.products[i].category === "Beers") {
          findBeerQuantity = findBeerQuantity + element.products[i].totalDue;
        }
        if (element.products[i].category === "Cocktails") {
          findCocktailQuantity =
            findCocktailQuantity + element.products[i].totalDue;
        }
        if (element.products[i].category === "Food") {
          findFoodQuantity = findFoodQuantity + element.products[i].totalDue;
        }
        if (element.products[i].category === "Wine") {
          findWineQuantity = findWineQuantity + element.products[i].totalDue;
        }
        if (element.products[i].category === "Hot drinks") {
          findHotDrinksQuantity =
            findHotDrinksQuantity + element.products[i].totalDue;
        }
        if (element.products[i].category === "Other") {
          findOtherQuantity = findOtherQuantity + element.products[i].totalDue;
        }
      }
      if (paymentMethod && paymentMethod !== "all") {
        findRevenue =
          findRevenue +
          (paymentMethod === "card" ? element.cardAmount : element.cashAmount);
      } else {
        findRevenue = findRevenue + element.cardAmount + element.cashAmount;
      }
    });

    // revenue for graph
    setsoftRevenue(findSoftQuantity);
    setBeerRevenue(findBeerQuantity);
    setCocktailRevenue(findCocktailQuantity);
    setFoodRevenue(findFoodQuantity);
    setWineRevenue(findWineQuantity);
    setHotDrinkRevenue(findHotDrinksQuantity);
    setOtherRevenue(findOtherQuantity);
    // revenue for graph

    setTotalRevenue(findRevenue);
    setItemSold(findQantity);
  };

  const reloadData = () => {
    if (orgId) {
      getOrgProduct();
      //getRevenueData();
      setPaymentMethod("");
      setDateRange([initialStartDate, initialEndDate]);
      getDataFromTimstamp();
    }
  };

  useEffect(() => {
    reloadData();
  }, [orgId, dateResetHandle, reloading]);

  const tableFilter = () => {
    if (filter.toLowerCase() === "all") {
      setProductData(productDataInitial);
    } else {
      let tempData = productDataInitial.filter(
        (p) => p.category.toLowerCase() === filter.toLowerCase()
      );
      console.log("tableFilter");
      console.log(tempData);
      setProductData(tempData);
    }
  };

  useEffect(() => {
    tableFilter();
  }, [filter]);

  const getDataFromTimstamp = async () => {
    try {
      setLoader(true);
      var startDate1 = startDate.getTime();
      var endDate1 = endDate.getTime();

      var start = new Date(startDate1);
      start.setHours(start.getHours());

      var end = new Date(endDate1);
      end.setHours(end.getHours() + 24);

      let records = [];
      const chunkSize = 10; // Firestore limit for 'in' query
      const orgIdChunks = [];

      // Split orgIds into chunks
      for (let i = 0; i < orgId.length; i += chunkSize) {
        orgIdChunks.push(orgId.slice(i, i + chunkSize));
      }

      for (const chunk of orgIdChunks) {
        const q = query(
          collection(db, "Revenues"),
          where("orgId", "in", chunk),
          where("timestamp", ">", start.getTime().toString()),
          where("timestamp", "<", end.getTime().toString())
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          records.push(doc.data());
        });
      }

      setOrderData(records);
      setOrderDataInitial(records);
      findPDetail(records);
      setPaymentMethod("");
    } catch (error) {
      console.error("Error fetching data from timestamp:", error);
      alert("An error occurred while fetching data. Please try again later.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (submitTimeCount !== 0) {
      getDataFromTimstamp();
    }
    setSubmitTimeCount(submitTimeCount + 1);
  }, [dateClickHandle]);

  const getDataByPaymentMethod = () => {
    //setLoader(true);

    let endData = [];
    if (paymentMethod === "card") {
      endData = orderDataInitial.filter((d) => d.paymentType === "card");
    } else if (paymentMethod === "cash") {
      endData = orderDataInitial.filter((d) => d.paymentType === "cash");
    } else {
      endData = orderDataInitial;
    }
    setOrderData(endData);
    findPDetail(endData);
    //setLoader(false);
  };

  useEffect(() => {
    if (paymentMethod) {
      findPDetail(orderData);
    }
  }, [paymentMethod]);

  const handleClickLiquidity = (item) => {
    setLiquiditySelected(item);
    handleShow();
  };

  const submitEditLiquidity = () => {
    setLoader(true);
    if (liquiditySelected?.maxLiquidity && liquiditySelected?.liquidity) {
      const postListRef = doc(db, "Organizations", liquiditySelected?.id);
      updateDoc(postListRef, {
        maxLiquidity: liquiditySelected?.maxLiquidity,
        liquidity: liquiditySelected?.liquidity,
      })
        .then((res) => {
          handleClose();
          setLiquiditySelected({});
          setLoader(false);
          for (let i = 0; i < organizations.length; i++) {
            if (organizations[i].id === liquiditySelected?.id) {
              organizations[i].liquidityAmount =
                liquiditySelected?.liquidityAmount;
              organizations[i].liquidity = liquiditySelected?.liquidity;
            }
          }
          alert.show(t("liquidity_updated"), { type: "success" });
        })
        .catch((err) => {
          console.log(err);
          alert.show(t("something_went_wrong"), { type: "error" });
          setLoader(false);
        });
    } else {
      alert.show(t("please_fill_all_fields"), { type: "error" });
      setLoader(false);
    }
  };

  return (
    <>
      <div className="container-fluid dashboardMain">
        <div className="row mx-0">
          <div className="col-xxl-8 col-xl-9">
            <div className="row row1">
              <div className="col-lg-5 order-2 order-md-1">
                <div className="themeCard itemCard">
                  <div className="itemImg">
                    <img className="img-fluid" src={itemSoldImg} alt="" />
                  </div>
                  <div className="content">
                    <h3>{itemSold}</h3>
                    <p>{t("products_sold")}</p>
                  </div>
                </div>
                <div className="themeCard itemCard mb-0">
                  <div className="itemImg">
                    <img className="img-fluid" src={totalRevenueImg} alt="" />
                  </div>
                  <div className="content">
                    <h3>
                      {totalRevenue > 0
                        ? totalRevenue.toFixed(2)
                        : totalRevenue}{" "}
                      €
                    </h3>
                    <p>{t("total_revenue")}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 order-1 order-md-2">
                <div className="themeCard col2">
                  <h3>{t("progress")}</h3>
                  <div className="chartRow">
                    <div className="chartMain">
                      <RevenueChart
                        softRevenue={softRevenue}
                        beerRevenue={beerRevenue}
                        cocktailRevenue={cocktailRevenue}
                        hotDrinkRevenue={hotDrinkRevenue}
                        foodRevenue={foodRevenue}
                        wineRevenue={wineRevenue}
                        otherRevenue={otherRevenue}
                      />
                    </div>
                    <div className="content">
                      <div className="colorRow">
                        <div className="circle blue"></div>
                        {beerRevenue
                          ? ((beerRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("beers")}
                      </div>
                      <div className="colorRow">
                        <div className="circle pink"></div>
                        {softRevenue
                          ? ((softRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("softs")}
                      </div>
                      <div className="colorRow">
                        <div className="circle green"></div>
                        {foodRevenue
                          ? ((foodRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("food")}
                      </div>
                      <div className="colorRow">
                        <div className="circle yellow"></div>
                        {cocktailRevenue
                          ? ((cocktailRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("cocktails")}
                      </div>
                      <div className="colorRow">
                        <div className="circle orange"></div>
                        {wineRevenue
                          ? ((wineRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("wine")}
                      </div>
                      <div className="colorRow">
                        <div className="circle red"></div>
                        {hotDrinkRevenue
                          ? ((hotDrinkRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("hot_drinks")}
                      </div>
                      <div className="colorRow">
                        <div className="circle purple"></div>
                        {otherRevenue
                          ? ((otherRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("others")}
                      </div>
                      <div className="total dn-sm">
                        {t("total_revenue")}: €{" "}
                        {totalRevenue > 0
                          ? totalRevenue.toFixed(2)
                          : totalRevenue}
                      </div>
                    </div>
                  </div>
                  <div className="total dn-lg ">
                    {t("total_revenue")}: €{" "}
                    {totalRevenue > 0 ? totalRevenue.toFixed(2) : totalRevenue}
                  </div>
                </div>
              </div>
            </div>

            <Table
              productData={productData}
              orderData={orderData}
              totalRevenue={totalRevenue}
              tableFilter={tableFilter}
              setFilter={setFilter}
              filter={filter}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="col-xxl-4 col-xl-3">
            <div className="amountMainCard themeCard">
              <h4>{t("liquidity_per_cash_register")}</h4>
              {organizations.map((item, key) => (
                <>
                  {orgId.includes(item?.id) && (
                    <div
                      key={key}
                      className={
                        parseFloat(item?.liquidity) -
                          parseFloat(item?.maxLiquidity) <
                          1000 &&
                        parseFloat(item?.liquidity) >
                          parseFloat(item?.maxLiquidity)
                          ? "priceMain card2"
                          : parseFloat(item?.liquidity) -
                              parseFloat(item?.maxLiquidity) >=
                              1000 &&
                            parseFloat(item?.liquidity) >
                              parseFloat(item?.maxLiquidity)
                          ? "priceMain card3"
                          : "priceMain card1"
                      }
                      onClick={() => handleClickLiquidity(item)}
                    >
                      <div className="liquidate">{item?.organization}</div>
                      <div className="price">
                        {item?.liquidity
                          ? parseFloat(item?.liquidity).toFixed(2)
                          : 0}{" "}
                        €
                      </div>
                      <div className="liquidate">{t("total_liquidity")}</div>
                      <div className="max">
                        {t("max_liquidity")}:{" "}
                        {item?.maxLiquidity
                          ? parseFloat(item?.maxLiquidity).toFixed(2)
                          : 0}{" "}
                        €
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader2 />}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("update_liquidity")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="liquidityEditForm">
            <div className="mb-3">
              <label>{t("max_liquidity")}</label>
              <input
                name="maxLiquidity"
                value={liquiditySelected?.maxLiquidity}
                onChange={(e) =>
                  setLiquiditySelected({
                    ...liquiditySelected,
                    [e.target.name]: e.target.value,
                  })
                }
                className="form-control"
                placeholder={t("max_liquidity")}
                type="text"
              />
            </div>
            <div className="mb-3">
              <label>{t("liquidity")}</label>
              <input
                name="liquidity"
                value={liquiditySelected?.liquidity}
                onChange={(e) =>
                  setLiquiditySelected({
                    ...liquiditySelected,
                    [e.target.name]: e.target.value,
                  })
                }
                className="form-control"
                placeholder={t("liquidity")}
                type="text"
              />
            </div>
            <button
              className="themeBtn w-100 mt-4"
              onClick={submitEditLiquidity}
            >
              {t("update")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Table = ({
  productData,
  orderData,
  totalRevenue,
  filter,
  setFilter,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation("dashboard");

  const handleExport = () => {
    exportToXlsx(productData, orderData, totalRevenue, startDate, endDate);
  };

  return (
    <div className="card themeCard themeTable br-10 border-transparent ">
      <div className="card-header bg-white border-transparent pt-3 d-flex justify-content-between align-items-center">
        <h6 className="m-0 tableHeading">{t("products_sold")}</h6>
        <button
          className="themeBtn py-1 min-h-40 rounded-full m-0 text-capitalize min-w-131px"
          type="button"
          onClick={handleExport}
        >
          {t("export")}
        </button>
      </div>

      <div className="filterRow">
        <div
          className={filter === "All" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("All")}
        >
          <All />
          <span>{t("all")}</span>
        </div>
        <div
          className={filter === "Beers" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Beers")}
        >
          <Beer />
          <span>{t("beers")}</span>
        </div>
        <div
          className={filter === "Softs" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Softs")}
        >
          <Softs />
          <span>{t("softs")}</span>
        </div>
        <div
          className={filter === "Food" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Food")}
        >
          <Food />
          <span>{t("food")}</span>
        </div>
        <div
          className={filter === "Cocktails" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Cocktails")}
        >
          <Cocktails />
          <span>{t("cocktails")}</span>
        </div>
        <div
          className={filter === "Wine" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Wine")}
        >
          <Wine />
          <span>{t("wine")}</span>
        </div>
        <div
          className={filter === "Hot drinks" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Hot drinks")}
        >
          <HotDrinks />
          <span>{t("hot_drinks")}</span>
        </div>
        <div
          className={filter === "Others" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Others")}
        >
          <Others />
          <span>{t("others")}</span>
        </div>
      </div>
      <div className="card-header br-10 table-responsive bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">{t("product")}</th>
              <th scope="col">{t("number_sold")}</th>
              <th scope="col">{t("price")}</th>
              <th scope="col">{t("total_percentage")}</th>
              <th scope="col">{t("revenue")}</th>
            </tr>
          </thead>
          <tbody>
            {productData.map((val, key) => (
              <TableRow
                key={val.id}
                val={val}
                orderData={orderData}
                totalRevenue={totalRevenue}
              />
            ))}
            {productData.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <h4 className="text-center">{t("no_data")}</h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function TableRow({ val, orderData, totalRevenue }) {
  const [prodQuantity, setProdQuantity] = useState(0);
  const [prodPrice, setProdPrice] = useState(0);

  const findPDetail = () => {
    let findOrder = [];
    let findQantity = 0;
    let findPrice = 0;
    // console.log("orderData");
    // console.log(orderData);
    var order = orderData.forEach((element) => {
      let dd = element.products.find(
        (p) =>
          p.title === val.title &&
          p.category === val.category &&
          p.price === val.price
      );
      if (dd) {
        findOrder.push(dd);
        findQantity = findQantity + dd.quantity;
        findPrice = findPrice + dd.quantity * dd.price;
      }
    });
    setProdPrice(findPrice);
    setProdQuantity(findQantity);
  };

  useEffect(() => {
    findPDetail();
  }, [orderData]);

  return (
    <tr>
      <td scope="row">
        <div className="profileDiv">
          <div className="imgDiv">
            <img
              src={
                val?.customIcon
                  ? val.category === "Food"
                    ? FoodArray.find((i) => i.name === val?.customIcon).icon
                    : val.category === "Softs"
                    ? SoftsArray.find((i) => i.name === val?.customIcon).icon
                    : val.category === "Beers"
                    ? BeersArray.find((i) => i.name === val?.customIcon).icon
                    : val.category === "Cocktails"
                    ? CocktailsArray.find((i) => i.name === val?.customIcon)
                        .icon
                    : val.category === "Wine"
                    ? WineArray.find((i) => i.name === val?.customIcon).icon
                    : val.category === "Hot drinks"
                    ? HotDrinksArray.find((i) => i.name === val?.customIcon)
                        .icon
                    : val.category === "Other" &&
                      OtherArray.find((i) => i.name === val?.customIcon).icon
                  : val.category === "Food"
                  ? Icons.food
                  : val.category === "Softs"
                  ? Icons.soft
                  : val.category === "Beers"
                  ? Icons.beer
                  : val.category === "Cocktails"
                  ? Icons.cocktail
                  : val.category === "Hot drinks"
                  ? Icons.drink
                  : val.category === "Other" && Icons.others
              }
              alt=""
            />
          </div>
          <div className="name">{val?.title}</div>
        </div>
      </td>
      <td>{prodQuantity} Pcs</td>
      <td>{val?.price} €</td>
      {/* <td>€ {prodQuantity * val?.price}</td> */}

      <td>
        {totalRevenue ? ((prodPrice / totalRevenue) * 100).toFixed(2) : 0} %
      </td>

      <td>
        <div className={prodPrice >= 0 ? "price" : "refund"}>
          {prodPrice ? prodPrice.toFixed(2) : prodPrice} €
        </div>
      </td>
    </tr>
  );
}

function RevenueChart({
  softRevenue,
  beerRevenue,
  cocktailRevenue,
  hotDrinkRevenue,
  foodRevenue,
  wineRevenue,
  otherRevenue,
}) {
  const isBigScreen = useMediaQuery({ query: "(min-width: 576px)" });
  const [height, setHeight] = useState(170);
  const [width, setWidth] = useState(170);
  const [cx, setCx] = useState(80);
  const [cy, setCy] = useState(80);
  const [innerRadius, setInnerRadius] = useState(50);
  const [outerRadius, setOuterRadius] = useState(70);

  useEffect(() => {
    if (isBigScreen) {
      setHeight(170);
      setWidth(170);
      setCx(80);
      setCy(80);
      setInnerRadius(50);
      setOuterRadius(70);
    } else {
      setHeight(120);
      setWidth(120);
      setCx(53);
      setCy(53);
      setInnerRadius(40);
      setOuterRadius(53);
    }
  }, [isBigScreen]);

  const data = [
    { name: "Group A", value: beerRevenue || 10 },
    { name: "Group B", value: softRevenue || 10 },
    { name: "Group C", value: foodRevenue || 10 },
    { name: "Group D", value: cocktailRevenue || 10 },
    { name: "Group E", value: wineRevenue || 10 },
    { name: "Group F", value: hotDrinkRevenue || 10 },
    { name: "Group G", value: otherRevenue || 10 },
  ];
  const COLORS = [
    "#025ef3",
    "#F302CA",
    "#00af00",
    "#F2C94C",
    "#ffa500",
    "#ea0000",
    "#7600EF",
  ];

  return (
    // <ResponsiveContainer width="100%" height="100%">
    <PieChart width={width} height={height} onMouseEnter={""}>
      <Pie
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((key, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
    //  </ResponsiveContainer>
  );
}

export { Dashboard };
