import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { BackIcon } from "../../components/Icon";
import Input from "../../components/Input";
import CareModal from "./CareModal";
import { createCashlessPayment, getAds } from "../../utility";

import { setEmail, getEmail } from "../../redux/cart/reducer";

const CashlessPayment = () => {
  const [careModalOpen, setCareModalOpen] = useState(false);
  const [promo, setPromo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmailValue] = useState("");
  const [ad, setAd] = useState();
  const alert = useAlert();

  const navigate = useNavigate();
  const { orgId } = useParams();
  const cartStore = useSelector((state) => state.cartReducer.cartArray);
  const savedEmail = useSelector((state) => state.cartReducer.email);
  const dispatch = useDispatch();

  let total = 0;
  let fee = 0;

  cartStore?.map((i) => {
    total = parseFloat(i?.totalDue + total);
  });
  fee = parseFloat(total * 0.028);

  const fetchAdvertisement = async () => {
    const fetchedAd = await getAds("bannerAd");
    setAd(fetchedAd.ad);
  };

  useEffect(() => {
    if (savedEmail) {
      setEmailValue(savedEmail);
    }
    fetchAdvertisement();
  }, [savedEmail]);

  const handleEmailChange = (e) => {
    setEmailValue(e.target.value);
  };

  const handlePayment = async () => {
    const emailPattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    if (!email.match(emailPattern)) {
      alert.show("Email invalide.", {
        type: "error",
      });
    } else {
      setLoading(true);
      dispatch(setEmail(email));
      await createCashlessPayment(total, cartStore, email, orgId)
        .then(async () => {
          alert.show("Redirection en cours...", { type: "success" });
        })
        .catch((err) => {
          alert.show("Le paiement n'a pas fonctionnée!", { type: "error" });
          console.log("Failed to save the participant: " + err);
        });
      //navigate(`/order/${orgId}/success`);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main-page">
        <div
          className="container px-4 d-flex flex-column"
          style={{ minHeight: "100dvh" }}
        >
          <div className="page-title">
            <button
              type="button"
              className="back-icon"
              onClick={() => navigate(`/cashless/${orgId}`)}
            >
              <BackIcon />
            </button>
            Paiement
          </div>
          <div className="price--area mb-30">
            <h2 className="price">{total.toFixed(2)}€</h2>
            <div className="subtext">Total dû</div>
            <div className="text"> Frais QuickPay: {fee.toFixed(2)}€</div>
          </div>

          <div className="text-22 gilroy-bold text-white mb-15">
            Email for receipt
          </div>
          <Input
            placeholder={"johndoe@mail.com"}
            label="email"
            style={{ height: "60px", paddingInline: "20px" }}
            onChange={handleEmailChange}
            value={email}
          />

          {/* <div className="mb-44">
            <button
              type="button"
              className="submitBtn submitBtn2 m-0 text-capitalize"
              style={{
                background:
                  "linear-gradient(91.24deg, #00C29F 0.26%, #009FC2 99.58%)",
              }}
            >
              MasterCard/Visa, Bancontact, Maestro
            </button>
          </div>
          
          <br /> */}
          <div className="mt-auto text-center">
            {/* <div className="text-22 gilroy-bold text-white mb-15">
              Confirmer votre commande
            </div> */}
            <button
              type="button"
              className="submitBtn submitBtn2 m-0 mb-1"
              onClick={handlePayment}
            >
              {loading ? "Chargement..." : "Payer"}
            </button>

            <div className="mt-12 pb-4">
              Payer de manière sécurisée en ligne.
            </div>
          </div>
          {ad && (
            <div
              className="text-center"
              onClick={() => window.open(ad.url, "_blank")}
            >
              <img src={ad.image} alt="promo" className="mw-100" />
            </div>
          )}
        </div>
      </div>
      <CareModal open={careModalOpen} setOpen={setCareModalOpen} />
    </>
  );
};

export default CashlessPayment;
