import React from "react";
import ENV from "../env";

import ErrorSpinner from "../components/ErrorSpinner";

const NotFound = (props) => {

  return (
    <section className="event-desc-section">
      <div className="container">
          <div className="section-header">
            <h2 className="title">
              Oops!
            </h2>
            <ErrorSpinner height="30%" width="30%" />
            <div className="text">
            Cette page n'existe pas.
            </div>
              <div className="col-12">
              <button
                type="submit"
                className="submitBtn"
                onClick={() => {window.open(`https://comitty.be`, '_parent', 'noreferrer')}}
              >
                Retour sur Comitty
              </button>
            </div>
          </div>
      </div>
    </section>
  );
};

export default NotFound;
