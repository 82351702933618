import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

import { db } from "./firebase";
import { Loader } from "./helper/loader";
import ProtectedRoutes from "./helper/secureRoute";
import EventCheckout from "./pages/EventCheckout";
import EventTicketing from "./pages/EventTicketing";
import PageNotFound from "./pages/PageNotFound";
import Politics from "./pages/Politics";
import Subscribe from "./pages/Subscribe";
import CashlessPanier from "./pages/cashless/CashlesPanier";
import Cashless from "./pages/cashless/Cashless";
import CashlessPayment from "./pages/cashless/CashlessPayment";
import CashlessServed from "./pages/cashless/CashlessServed";
import CashlessSuccess from "./pages/cashless/CashlessSuccess";
import DashboardRouter from "./pages/dashboardPages/dashboardRoute";
import { ForgetPass } from "./pages/forgetPass/forgetPass";
import { Login } from "./pages/login/login";
import userAction from "./redux/users/action";

const Router = (props) => {
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.userReducer);
  const [loader, setLoader] = useState(true);

  const checkUserLoggedIn = async () => {
    const auth = getAuth();
    await onAuthStateChanged(auth, async (user) => {
      setLoader(true);
      if (user) {
        const userId = user.uid;
        const userRef = doc(db, "Store", userId);
        let loadedUser;

        await getDoc(userRef)
          .then((snapshot) => {
            const data = {
              id: snapshot.id,
              ...snapshot.data(),
            };
            loadedUser = data;
          })
          .catch((err) => {
            console.log("Fetch user failed: " + err);
          });

        dispatch(setUser(loadedUser));
        // navigate("/dashboard/overview");
        setLoader(false);
        // ...
      } else {
        // User is signed out
        console.log("User is signed out");
        // navigate("/login")
        setLoader(false);
        // ...
      }
    });
  };
  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/inscription" element={<Subscribe />} />
        <Route
          path="/checkout/:eventId/:participantId/:paymentStatus"
          element={<EventCheckout />}
        />
        {/* <Route
          path="/freecheckout/:eventId"
          element={<EventFakeParticipant />}
        /> */}
        <Route path="/events/:eventId" element={<EventTicketing />} />
        <Route exact path="/politics" component={Politics} />
        <Route path="/:page" element={<PageNotFound />} />
        <Route path="/" element={<PageNotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPass />} />
        <Route element={<ProtectedRoutes />}>
          <Route exact path="/dashboard/:next" element={<DashboardRouter />} />
        </Route>

        <Route path="/cashless/:orgId" element={<Cashless />} />
        <Route path="/cashless/:orgId/cart" element={<CashlessPanier />} />
        <Route path="/cashless/:orgId/payment" element={<CashlessPayment />} />
        <Route path="/cashless/:orgId/order/:orderId/success" element={<CashlessSuccess />} />
        <Route path="/cashless/:orgId/order/:orderId/served" element={<CashlessServed />} />
      </Routes>
      {loader && <Loader loader={loader} />}
    </>
  );
};

export default Router;
