import React from "react";
import bg1 from "./img/banner-bg.png";
import bg2 from "./img/banner-bg2.png";
import LoadingSpinner from "../components/LoadingSpinner";

const Banner = (props) => {
  const [mobileScreen, setMobileScreen] = React.useState(true);

  const handleMobile = () => {
    const width = window.innerWidth;
    if (width < 767) {
      setMobileScreen(true);
    } else {
      setMobileScreen(false);
    }
  };

  React.useEffect(() => {
    handleMobile();
    window.addEventListener("resize", handleMobile);
  }, []);
  return (
    <section
      className={props.event?.noTitle ? "clear-banner-section" : "banner-section"}
      style={{
        background: `url(${
          props.event ? props.event.imageUrl : ""
        }) no-repeat center center / cover`,
      }}
    >
      <div className="container">
        <div className="banner-content">
          {props.event?.name && !props.event?.noTitle ? (
            <h1
              className={props.event.name.length > 25 ? "long-title" : "title"}
            >
              {props.event.name}
            </h1>
          ) : (
            !props.event?.noTitle && <LoadingSpinner height="30%" width="30%" />
          )}
        </div>
      </div>
    </section>
  );
};

export default Banner;
