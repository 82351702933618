import ENV from "../env";

const getOrganization = async (orgId) => {
  const response = await fetch(`${ENV.firebaseApiUrl}/organization/${orgId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Get organization failed: " + resData.message);
  }

  return resData;
};

export default getOrganization;
