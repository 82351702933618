import React, { Component } from "react";
import emailjs from "emailjs-com";

export const sendQRCode = async props => {
  emailjs
    .send(
      "service_2us1vwk",
      "template_bmpkuw6",
      props,
      "user_Io6hQDXLobAbA5jUwZICt"
    )
    .then(
      (result) => {
        console.log(result.text);
        return true
      },
      (error) => {
        console.log(error.text);
        return false
      }
    );
};
